import React from 'react';
import styled, { keyframes } from 'styled-components'

const face = keyframes`
  0% { transform: scale(1) };
  50% { transform: scale(1.004) };
  100% { transform: scale(1) };
`;

const hand1 = keyframes`
  0% {transform: translateY(0);}
  50% {transform: translateY(-2px);}
  100% {transform: translateY(0);}
`;

const hand2 = keyframes`
  0% {transform: translateY(-3px);}
  50% {transform: translateY(0);}
  100% {transform: translateY(-3px);}
`;

const Container = styled.div`
    svg {
        path {
            &.st0{
                fill:#353559;
            }
            &.st1{
                opacity:0.4;
                /* fill:#A8C4E5; */
                fill:#f2f5fa;
            }
            &.st2{
                fill:#3E4A93;
            }
            &.st3{
                fill:#513554;
            }
            &.st4{
                fill:#8585FF;
            }
            &.st5{
                fill:#4757A5;
            }
            &.st6{
                fill:#EF9A78;
            }
            &.st7{
                fill:#FFAA82;
            }
            &.hand1{
                fill:#FFAA82;
                animation: ${hand1} 2s ease-in-out infinite;
            }
            &.hand2{
                fill:#FFAA82;
                animation: ${hand2} 2s ease-in-out infinite;
            }
            &.st8{
                fill:#514677;
            }
            &.st9{
                fill:#423A66;
            }
            &.st10{
                fill:#424268;
            }
            &.st11{
                fill:#8F90A2;
            }
            &.st12{
                fill:#A8C4E5;
            }
            &.st13{
                fill:#E9F5FF;
            }
            &.st14{
                fill:#FFFFFF;
            }
            &.st15{
                fill:#467DE4;
            }
            &.st16{
                fill:#3970C6;
            }
            &.st17{
                fill:#649BF2;
            }
            &.st18{
                fill:#2B337C;
            }
            &.st19{
                fill:#6B6B9B;
            }
            &.st20{
                fill:#AFC9DD;
            }
            &.st21{
                fill:#22BCFC;
            }
            &.st22{
                fill:#EA8A6C;
            }
            &.st23{
                fill:#46466B;
            }
            &.st24{
                fill:#3D4993;
            }
            &.st25{
                fill:#E1E8EF;
            }
            &.st26{
                fill:#E6E6ED;
            }
            &.st27{
                fill:#FBFBFF;
            }
            &.st28{
                fill:#915151;
            }
            &.st29{
                fill:#353559;
                stroke:#000000;
                stroke-miterlimit:10;
                animation: ${face} 2s ease-in-out infinite;
            }
            &.st30{
                opacity:0.17;
                fill:#A8C4E5;
            }
            &.st31{
                opacity:0.65;
                fill:#89B2DB;
            }
            &.st32{
                fill:#5366C4;
            }
            &.st33{
                fill:#A9C5E3;
            }
            &.st34{
                fill:#D4E1F1;
            }
            &.st35{
                fill:#8AACCE;
            }
            &.st36{
                fill:#3F4C91;
            }
            &.st37{
                fill:url(#SVGID_1_);
            }
            &.st38{
                fill:#B3D3F2;
            }
        }
        ellipse {
            &.st14{
                fill:#FFFFFF;
            }
            &.st28{
                fill:#915151;
            }
        }
        polygon {
            &.st14{
                fill:#FFFFFF;
            }
            &.st15{
                fill:#467DE4;
            }
        }
        stop {
            &.stop1 {
                stop-color:#FFFFFF;
            }
            &.stop2 {
                stop-color:#FCFDFE;
            }
            &.stop3 {
                stop-color:#F3F7FB;
            }
            &.stop4 {
                stop-color:#E4EDF7;
            }
            &.stop5 {
                stop-color:#CEDEF0;
            }
            &.stop6 {
                stop-color:#B3CBE8;
            }
            &.stop7 {
                stop-color:#A8C4E5
            }
        }
    }
`

const ProcessIllustration = () => {
    return (
        <Container>
            <svg x="0px" y="0px" viewBox="0 0 660 715.8">
                <g>
                    <path className="st0" d="M444.7,294.2l0.8-0.1c2.5-0.4,4.3-2.7,3.9-5.2l-20.9-140.7c-0.4-2.5-2.7-4.3-5.2-3.9l-0.8,0.1
                        c-2.5,0.4-4.3,2.7-3.9,5.2l20.9,140.7C439.9,292.9,442.2,294.6,444.7,294.2z"/>
                    <path className="st1" d="M312.5,552.5L24.4,383.1c-15.4-8.9-15.4-19.7-0.2-28.8l163.1-96.5l315.4,182.1L312.5,552.5z"/>
                    <g>
                        <path className="st2" d="M334.5,178.7l31.8-84c5.2-13.8,19.7-19.9,31.5-13.1l46.8,27.1c8.4,4.9,13.2,15.2,11.8,25.9l-13.9,109.3
                            L334.5,178.7z"/>
                        <path className="st3" d="M259.5,338.2l-0.8-0.1c-3.8-0.6-6.5-4.1-5.9-8l16.5-111.5c0.6-3.8,4.1-6.5,8-5.9l0.8,0.1
                            c3.8,0.6,6.5,4.1,5.9,8l-16.5,111.5C266.9,336.1,263.3,338.7,259.5,338.2z"/>
                        <path className="st3" d="M428.3,342.3l1.7-0.3c3.6-0.5,6.1-3.9,5.5-7.5l-14.3-96.4c-0.5-3.6-3.9-6.1-7.5-5.5l-1.7,0.3
                            c-3.6,0.5-6.1,3.9-5.5,7.5l14.3,96.4C421.4,340.3,424.7,342.8,428.3,342.3z"/>
                        <path className="st3" d="M341.7,388.3L341.7,388.3c-4.1-0.6-6.9-4.4-6.3-8.5l18.7-126.1c0.6-4.1,4.4-6.9,8.5-6.3l0,0
                            c4.1,0.6,6.9,4.4,6.3,8.5l-18.7,126.1C349.6,386.1,345.8,388.9,341.7,388.3z"/>
                        <path className="st0" d="M358.9,280.8l-89.9-51.9c-8.3-4.8-5-15.4,7.5-23.5l32.3-21c12.5-8.1,29.5-10.8,37.8-6l89.9,51.9
                            c8.3,4.8,5,15.4-7.5,23.5l-32.3,21C384.2,282.9,367.2,285.6,358.9,280.8z"/>
                        <path className="st4" d="M354,293.4l-79.5-45.9c-8.5-4.9-13.3-14.8-13.3-14.8c-3.2-7.3,4.4-12.7,13-7.7l83.6,49.2
                            c8.5,4.9,11.8,10.1,10.4,16.2C368.2,290.4,364.8,299,354,293.4z"/>
                        <path className="st5" d="M435,253.6l-63.4,38.8c-10.3,6.3-18.2,3.3-17.6-6.6l0.5-9.5c0.6-9.9,9.4-23.1,19.7-29.4l63.4-38.8
                            c10.3-6.3,18.2-3.3,17.6,6.6l-0.5,9.5C454.1,234.1,445.2,247.3,435,253.6z"/>
                    </g>
                    <g>
                        <g>
                            <path className="st6" d="M359.2,182.1c-17.3,7.5-32.3,20.2-45.4,33.5c-13.2,13.4-20.8,31.1-20,50c0.7,16.6,1.8,33.1,2.7,49.7
                                c0.4,6.9,0.7,13.8,1.1,20.6c0.3,6.1,5,11.4,11.4,11.4c5.9,0,11.7-5.2,11.4-11.4c-0.8-15.3-1.6-30.6-2.5-46
                                c-1-18-4.6-37.8,7.3-53.2c5.5-7.2,13.1-13.5,20.1-19.2c7.7-6.4,16.3-11.8,25.5-15.8c5.6-2.5,6.9-10.7,4.1-15.6
                                C371.4,180.4,364.9,179.7,359.2,182.1L359.2,182.1z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st7" d="M248.3,301.9c5.7-6,10.4-13,15.4-19.5c3-3.9,5.9-7.8,8.9-11.7c0.6-0.8,1.2-1.5,1.8-2.3
                                c-1.3,1.7,0,0,0.5-0.7c0.9-1.1,1.8-2.3,2.7-3.4c4.1-4.9,8.8-9.5,13.4-13.9c2.8-2.7,5.7-5.2,8.7-7.6c1.1-0.9,2.1-1.8,3.2-2.6
                                c0.6-0.4,1.4-0.8,2-1.2c1.5-1.3,0.2,0.8-0.4-0.3c0,0,0.6,0,0.7,0c1.2,0.3-1.9-0.4-1.7-0.2c0.6,0.3,1.7,0.4,2.3,0.6
                                c2,0.5,4,1.2,5.9,1.8c5.1,1.6,10.2,3.1,15.5,4c18.1,3,35.8,0,51-10.6c5-3.5,7.4-10,4.1-15.6c-2.9-5-10.5-7.6-15.6-4.1
                                c-11.4,7.9-24.2,10.3-36.7,7.7c-8.7-1.8-18.5-7.4-27.6-6.1c-8.4,1.1-14.6,7-20.9,12.2c-6.5,5.4-12.3,11.5-18,17.6
                                c-5.7,6-10.4,13-15.4,19.6c-1.8,2.4-3.6,4.8-5.4,7.1c-0.8,1-1.5,2-2.3,3c-0.5,0.6-2.7,3.4-1.3,1.7c-2.2,2.8-4.3,5.8-6.8,8.4
                                C222.2,296.5,238.3,312.6,248.3,301.9L248.3,301.9z"/>
                        </g>
                    </g>
                    <g>
                        <path className="st8" d="M235.4,316.3l-0.7-1.5c-0.4-0.8-0.1-1.8,0.8-2.2l0,0c0.8-0.4,1.8-0.1,2.2,0.8l0.7,1.5
                            c0.4,0.8,0.1,1.8-0.8,2.2l0,0C236.8,317.5,235.8,317.1,235.4,316.3z"/>
                        <path className="st9" d="M234.9,313.1l4.5-6.1c0.5-0.7,1.6-0.9,2.3-0.3l0,0c0.7,0.5,0.9,1.6,0.3,2.3l-4.5,6.1
                            c-0.5,0.7-1.6,0.9-2.3,0.3h0C234.5,314.8,234.3,313.8,234.9,313.1z"/>
                        <g>
                            <path className="st10" d="M257.3,294.2c0.6-4-1-10-3.5-10.9l-12.2,4.8l-1-0.4c-5.9,1.6-33.7,6.7-42.3,9.5c-4.4,1.4-0.8,7-0.8,7
                                c9.1,9.8,23.1,12.8,23.1,12.8c6.8,0.3,7-6.1,16.5-9.4c1.6-0.6,4.3-0.4,3.8,0.9l-5.7,6.9l2.9,1.4c0,0,9.1-9.1,15.6-15
                                C256.3,299.3,257.6,296.5,257.3,294.2z"/>
                            <path className="st7" d="M252.9,281.5l-7.5,0.4c0,0-25.3,9.6-30.2,11.7c-5.7,2.3,4.2,21.1,33.4,3.9
                                C266.1,290,252.9,281.5,252.9,281.5z"/>
                        </g>
                    </g>
                    <path className="st11" d="M343.2,166.9c-39,24.7-62.8,47.3-61.3,58c0,0,19.4-10.8,34.7-1.9c22.6,13.1,1.4,25.3,1.4,25.3
                        s85.8,13,90.1-32.4c2.8-28.8-3-35.3-3-35.3S364.4,194.6,343.2,166.9z"/>
                    <g>
                        <path className="st8" d="M317.9,358.6l-1.4-0.7c-0.8-0.4-1.1-1.4-0.7-2.3l0,0c0.4-0.8,1.4-1.1,2.3-0.7l1.4,0.7
                            c0.8,0.4,1.1,1.4,0.7,2.3l0,0C319.7,358.7,318.7,359,317.9,358.6z"/>
                        <path className="st9" d="M315.5,356.3l0-7.6c0-0.9,0.8-1.7,1.7-1.7l0,0c0.9,0,1.7,0.8,1.7,1.7l0,7.6c0,0.9-0.8,1.7-1.7,1.7l0,0
                            C316.3,358,315.5,357.2,315.5,356.3z"/>
                        <g>
                            <path className="st10" d="M322.3,327.8c-1.9-3.6-6.7-7.5-9.3-6.7l-6.9,11.1l-1,0.3c-3.8,4.8-23.1,25.5-28.3,32.9
                                c-2.7,3.8,3.5,6.1,3.5,6.1c13.1,2.4,26.2-3.5,26.2-3.5c5.6-3.8,1.9-9.1,7.7-17.4c1-1.4,3.2-2.9,3.6-1.5l-0.5,8.9l3.1-0.6
                                c0,0,1.9-12.8,3.6-21.4C324.6,332.4,323.9,329.5,322.3,327.8z"/>
                            <path className="st6" d="M311.2,320.2l-5.8,4.8c0,0-14.6,22.8-17.3,27.4c-3.2,5.3,16,14.4,29.1-16.7
                                C326.8,319.2,311.2,320.2,311.2,320.2z"/>
                        </g>
                    </g>
                    <path className="st12" d="M505.2,448l0.8-0.1c2.5-0.4,4.3-2.7,3.9-5.2L489,302c-0.4-2.5-2.7-4.3-5.2-3.9l-0.8,0.1
                        c-2.5,0.4-4.3,2.7-3.9,5.2l20.9,140.7C500.3,446.7,502.7,448.4,505.2,448z"/>
                    <path className="st12" d="M308.7,565.8L308.7,565.8c-2.7-0.2-4.8-2.7-4.5-5.4l19.6-227.6c0.2-2.7,2.7-4.8,5.4-4.5l0,0
                        c2.7,0.2,4.8,2.7,4.5,5.4l-19.6,227.6C313.9,564,311.5,566,308.7,565.8z"/>
                    <polygon className="st0" points="324.6,439 315.2,432.5 317.4,404 328,406.7 		"/>
                    <polygon className="st0" points="487.1,357.2 496.2,350 492.1,322.8 482.2,325 		"/>
                    <path className="st0" d="M7.5,375.9L7.5,375.9c-2.3-0.3-3.8-2.4-3.5-4.7l26-175c0.3-2.3,2.4-3.8,4.7-3.5l0,0c2.3,0.3,3.8,2.4,3.5,4.7
                        l-26,175C11.8,374.7,9.7,376.3,7.5,375.9z"/>
                    <g>
                        <rect x="0.1" y="206.5" className="st12" width="26.5" height="15.4"/>
                        <rect x="481.3" y="304.8" className="st12" width="26.5" height="15.4"/>
                        <path className="st12" d="M330.3,415.8L3.5,227.1c-5.1-3-4.5-8.1,1.4-11.5l152.5-88.1c5.9-3.4,14.9-3.8,20-0.8l326.9,188.7
                            c5.1,3,4.5,8.1-1.4,11.5l-152.5,88.1C344.4,418.4,335.4,418.7,330.3,415.8z"/>
                        <path className="st13" d="M330.3,401L3.5,212.3c-5.1-3-4.5-8.1,1.4-11.5l152.5-88.1c5.9-3.4,14.9-3.8,20-0.8l326.9,188.7
                            c5.1,3,4.5,8.1-1.4,11.5l-152.5,88.1C344.4,403.6,335.4,404,330.3,401z"/>
                    </g>
                    <g>
                        <path className="st12" d="M348,241.5l-95.2-55c-0.5-0.3-1.2-0.3-1.8,0l-28.9,17.1c-1.2,0.7-1.1,2.3,0,3l94.9,54.8
                            c0.5,0.3,1.2,0.3,1.7,0l29.2-16.9C349.1,243.8,349.1,242.2,348,241.5z"/>
                        <path className="st14" d="M317,257.8L222.1,203c-1.2-0.7-1-2.4,0.4-3.2l33.1-18.7c0.6-0.4,1.3-0.4,1.9-0.1l95.2,55
                            c1.2,0.7,1,2.4-0.4,3.2l-33.4,18.5C318.3,258.1,317.5,258.1,317,257.8z"/>
                        <path className="st12" d="M251.3,195.9c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L251.3,195.9z"/>
                        <path className="st12" d="M258.7,207c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L258.7,207z"/>
                        <path className="st12" d="M255,211.5c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L255,211.5z"/>
                        <path className="st12" d="M244.8,212.4c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L244.8,212.4z"/>
                        <path className="st12" d="M340.1,240c-0.2,0.1-0.5,0-0.8-0.2l-8.4-4.8c-0.3-0.2-0.4-0.4-0.3-0.5l5-2.9c0.1-0.1,0.5,0,0.8,0.2l8.4,4.8
                            c0.3,0.2,0.4,0.4,0.3,0.5L340.1,240z"/>
                        <path className="st12" d="M262.3,189c-0.1,0.1-0.4,0-0.6-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.2-0.4l1.8-1.1c0.1-0.1,0.4,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.2,0.4L262.3,189z"/>
                        <path className="st12" d="M270.9,194.1c-0.1,0.1-0.4,0-0.6-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.2-0.4l1.8-1.1c0.1-0.1,0.4,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.2,0.4L270.9,194.1z"/>
                        <path className="st12" d="M279.9,199.2c-0.1,0.1-0.4,0-0.6-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.2-0.4l1.8-1c0.1-0.1,0.4,0,0.6,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.2,0.4L279.9,199.2z"/>
                        <path className="st12" d="M267,196.6c-0.1,0.1-0.4,0-0.6-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.2-0.4l1.8-1c0.1-0.1,0.4,0,0.6,0.1l6.4,3.7
                            c0.2,0.1,0.3,0.3,0.2,0.4L267,196.6z"/>
                        <path className="st12" d="M328.2,231.7c-0.2,0.1-0.5,0.1-0.7-0.1l-41.6-24c-0.2-0.1-0.3-0.3-0.1-0.4l5-2.9c0.1-0.1,0.5,0,0.7,0.1
                            l41.6,24c0.2,0.1,0.3,0.3,0.1,0.4L328.2,231.7z"/>
                        <path className="st12" d="M321.4,227.9c-0.2,0.1-0.5,0.1-0.7-0.1l-41.6-24c-0.2-0.1-0.3-0.3-0.1-0.4l5-2.9c0.1-0.1,0.5,0,0.7,0.1
                            l41.6,24c0.2,0.1,0.3,0.3,0.1,0.4L321.4,227.9z"/>
                        <path className="st12" d="M253.6,217.5c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L253.6,217.5z"/>
                        <path className="st12" d="M262.4,222.6c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L262.4,222.6z"/>
                        <path className="st12" d="M271.2,227.6c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L271.2,227.6z"/>
                        <path className="st12" d="M280,232.7c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L280,232.7z"/>
                        <path className="st12" d="M288.8,237.8c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L288.8,237.8z"/>
                        <path className="st12" d="M297.6,242.9c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L297.6,242.9z"/>
                        <path className="st12" d="M317.5,254.4c-0.2,0.1-0.5,0.1-0.7-0.1l-17.4-10.1c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5
                            c0.2-0.1,0.5-0.1,0.7,0.1l17.4,10.1c0.2,0.1,0.3,0.3,0.1,0.4L317.5,254.4z"/>
                        <path className="st12" d="M263.7,216.6c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L263.7,216.6z"/>
                        <path className="st12" d="M272.5,221.7c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L272.5,221.7z"/>
                        <path className="st12" d="M281.4,226.8c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L281.4,226.8z"/>
                        <path className="st12" d="M290.2,231.8c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L290.2,231.8z"/>
                        <path className="st12" d="M299,236.9c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L299,236.9z"/>
                        <path className="st12" d="M307.8,242c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L307.8,242z"/>
                        <path className="st12" d="M316.6,247.1c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L316.6,247.1z"/>
                        <path className="st12" d="M267.5,212c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L267.5,212z"/>
                        <path className="st12" d="M276.3,217.1c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L276.3,217.1z"/>
                        <path className="st12" d="M285.1,222.2c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L285.1,222.2z"/>
                        <path className="st12" d="M293.9,227.3c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L293.9,227.3z"/>
                        <path className="st12" d="M302.7,232.4c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L302.7,232.4z"/>
                        <path className="st12" d="M311.6,237.4c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5,0,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L311.6,237.4z"/>
                        <path className="st12" d="M320.4,242.5c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L320.4,242.5z"/>
                        <path className="st12" d="M249.9,201.9c-0.2,0.1-0.5,0.1-0.7-0.1l-10.9-6.3c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5
                            c0.2-0.1,0.5-0.1,0.7,0.1L254,199c0.2,0.1,0.3,0.3,0.1,0.4L249.9,201.9z"/>
                        <path className="st12" d="M335,244.2c-0.2,0.1-0.5,0.1-0.7-0.1l-10.9-6.3c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l10.9,6.3c0.2,0.1,0.3,0.3,0.1,0.4L335,244.2z"/>
                        <path className="st12" d="M246.1,206.4c-0.2,0.1-0.5,0.1-0.7-0.1l-12.9-7.5c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5
                            c0.2-0.1,0.5-0.1,0.7,0.1l12.9,7.5c0.2,0.1,0.3,0.3,0.1,0.4L246.1,206.4z"/>
                        <path className="st12" d="M236,207.3c-0.2,0.1-0.5,0.1-0.7-0.1l-8.6-5c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.5c0.2-0.1,0.5-0.1,0.7,0.1
                            l8.6,5c0.2,0.1,0.3,0.3,0.1,0.4L236,207.3z"/>
                        <path className="st12" d="M260.1,201c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L260.1,201z"/>
                        <path className="st12" d="M268.9,206.1c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L268.9,206.1z"/>
                        <path className="st12" d="M277.7,211.2c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L277.7,211.2z"/>
                        <path className="st12" d="M286.5,216.2c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L286.5,216.2z"/>
                        <path className="st12" d="M295.3,221.3c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L295.3,221.3z"/>
                        <path className="st12" d="M304.1,226.4c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L304.1,226.4z"/>
                        <path className="st12" d="M312.9,231.5c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L312.9,231.5z"/>
                        <path className="st12" d="M321.8,236.6c-0.2,0.1-0.5,0.1-0.7-0.1l-6.4-3.7c-0.2-0.1-0.3-0.3-0.1-0.4l4.2-2.4c0.2-0.1,0.5-0.1,0.7,0.1
                            l6.4,3.7c0.2,0.1,0.3,0.3,0.1,0.4L321.8,236.6z"/>
                        <path className="st12" d="M326.9,241.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0l-4.2,2.5c-0.1,0-0.1,0.1-0.1,0.2
                            c0,0.1,0.1,0.2,0.2,0.2l1.7,1l-5.6,3.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2l4.3,2.5c0.1,0.1,0.2,0.1,0.4,0.1
                            c0.1,0,0.2,0,0.3,0l10.1-5.8c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2L326.9,241.1z"/>
                    </g>
                    <g>
                        <path className="st15" d="M284.5,282c-6.1,3.5-16,3.5-22.1,0c-6.1-3.5-6.2-9.2-0.1-12.8c6.1-3.5,16-3.5,22.1,0
                            C290.5,272.8,290.6,278.5,284.5,282z"/>
                        <polygon className="st15" points="286.4,275.9 257.8,275.8 257.8,250.3 286.4,250.5 			"/>
                        <path className="st16" d="M280.5,250.4v23.3l0,0.9c0,3.9-2,4.6-5.1,6.4c-1.8,1.1-4.1,2.2-6.4,2.6c5.4,1,11.4-0.4,15.6-2.9
                            c3.1-1.8,4.5-3.1,4.5-7.1h0l-0.1-23.1L280.5,250.4z"/>
                        
                            <ellipse transform="matrix(5.416273e-03 -1 1 5.416273e-03 21.3735 522.5699)" className="st14" cx="273.4" cy="250.5" rx="9" ry="15.6"/>
                        <path className="st17" d="M282.6,255.7c-5,2.9-13.2,2.9-18.3,0c-5.1-2.9-5.1-7.6-0.1-10.5c5-2.9,13.2-2.9,18.3,0
                            C287.6,248.1,287.7,252.8,282.6,255.7z"/>
                        <path className="st18" d="M282.6,249.7c-5.1-2.9-13.2-2.9-18.3,0c-1.5,0.9-2.5,1.9-3.1,3c0.6,1.1,1.7,2.1,3.2,3
                            c5.1,2.9,13.2,2.9,18.3,0c1.5-0.9,2.5-1.9,3.1-3C285.2,251.6,284.1,250.6,282.6,249.7z"/>
                        <path className="st19" d="M264.3,255.7c-1.5-0.9-2.5-1.9-3.1-3c0.3-0.6,0.8-1.2,1.5-1.8c4.4-0.7,9.3-0.1,12.7,1.9
                            c1.5,0.9,2.5,1.9,3.1,3c0.3,0.5,0.5,1,0.5,1.4c-0.8,0.2-1.4,0.3-2,0.4C272.6,258.3,267.7,257.7,264.3,255.7z"/>
                        <g>
                            <g>
                                <path className="st18" d="M285.1,258.6c2.6,1.4,6.2,2.7,6.9,5.8c0.8,3.4,0.3,7.6,0.2,11.1c-0.1,2.3-0.4,5.1-3.3,5.4
                                    c-2,0.2-4.4-1.5-6-2.3c-2.3-1.2-0.3-4.6,2-3.5c0.9,0.4,1.9,1.3,2.9,1.4c1.2,0.2,0.5-0.2,0.4-1.6c-0.1-0.9,0-1.9,0-2.8
                                    c0-1.8,0.2-3.9-0.1-5.7c-0.5-2.6-3-3.3-5.1-4.4C280.8,260.9,282.8,257.4,285.1,258.6L285.1,258.6z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="hand1" d="M346.3,78.2c-5.1,10.6-11.1,20.6-18.9,29.4c-6.2,7-12.7,13.8-17.7,21.7c-3.9,6.2-7.1,12.9-10.6,19.3
                                c-1.3,2.3-2.7,4.5-4.2,6.7c-0.4,0.6-1.2,1.5-0.3,0.4c-0.4,0.5-0.9,1.1-1.3,1.6c-1.1,1.3-2.2,2.5-3.4,3.7
                                c-8.7,8.8-18.8,16.2-28.7,23.7c-3.7,2.8-5.6,7.4-3.1,11.7c2.1,3.6,8,5.9,11.7,3.1c15-11.3,32.3-22.7,42.2-39.1
                                c3.3-5.5,6-11.3,9.1-16.9c4.6-8.2,10.2-14.8,16.5-21.7c9.5-10.5,17.2-22,23.4-34.9C365.8,76.9,351.1,68.3,346.3,78.2L346.3,78.2
                                z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st20" d="M334.2,81.2c-9.5,18.7-21.1,35.9-31.3,54.2c-7.1,12.8,12.5,24.3,19.6,11.5c10.2-18.3,21.9-35.4,31.3-54.2
                                c2.8-5.5,1.4-12.3-4.1-15.6C344.7,74.1,337,75.7,334.2,81.2L334.2,81.2z"/>
                        </g>
                    </g>
                    <path className="st21" d="M339.2,92.6c0,0-11.8,21.8-9.2,34.8c1.7,8.3,8.3,17.6,9.4,20.2c8.8,21.7,5.1,19.2,6.3,25.2
                        c0.8-2,8.4,25.2,55.4,9.6l16-63.3L339.2,92.6z"/>
                    <path className="st21" d="M412.3,138.6c1.8-1.2,4.8-15.8,4.8-15.8c0.6-8.7-0.8-20.2-8.1-29.7c-3.7-4.9-29.6-16.6-49.7-20
                        c-9.7-1.7-18.8,3.9-21.9,13c-1.8,5.3-3.2,15.9-3.2,15.9c0.7,20.7,7.5-6.6,9.9,4.8c-0.1-0.3-0.2-0.6-0.2-0.8c0,0,4,19.2,7.1,34.1
                        c1.9,9.3,3.3,16,3.4,16.9c0,0,0,0.1,0,0.1l36.5,27.1l15.4-33.3l0,0L412.3,138.6z"/>
                    <path className="st7" d="M360.5,72.9c0,0-15.7,18.2,0.2,30.8c15.9,12.6,38.9-16.2,38.9-16.2C385.7,79.1,372.4,73.5,360.5,72.9z"/>
                    <path className="st7" d="M364.1,81.6l-0.4-35.8l19.4,11.9l0.3,29.2C383.8,91.8,366.1,100.4,364.1,81.6z"/>
                    <path className="st22" d="M363.7,77.2c0,0,9.6-5.2,9.7-5.3c0,0-3.2,7.2-8,10L363.7,77.2z"/>
                    <path className="st7" d="M314.7,240.6L314.7,240.6c-1.2-5.3,2.1-10.6,7.4-11.8l18.2-4.2c5.3-1.2,10.6,2.1,11.8,7.4l0,0
                        c1.2,5.3-2.1,10.6-7.4,11.8l-18.2,4.2C321.2,249.1,315.9,245.8,314.7,240.6z"/>
                    <g>
                        <path className="st23" d="M337.2,168.5L337.2,168.5c-0.5,1.4-0.3,3,0.7,4.1c3.6,4.1,13.6,13.2,31.7,14.6c15.5,1.2,25-0.5,29.7-1.8
                            c2-0.6,3.3-2.5,2.9-4.6l-0.2-1.3c-0.4-2.3-2.6-3.8-4.8-3.3c-8.7,1.9-29.1,5.3-41.8-1.2c-5.9-3-9.5-5.7-11.7-8
                            C341.8,165,338.3,165.7,337.2,168.5z"/>
                        <path className="st14" d="M360.1,186.8l-8.3-3.3c-2.8-1.1-4.6-4.3-4.1-7.2l0,0c0.5-2.9,3.2-4.3,6-3.2l8.3,3.3
                            c2.8,1.1,4.6,4.3,4.1,7.2v0C365.6,186.5,362.9,187.9,360.1,186.8z"/>
                        <path className="st23" d="M358.5,183.9l-4.8-1.9c-1.6-0.6-2.7-2.5-2.3-4.2l0,0c0.3-1.7,1.9-2.5,3.5-1.9l4.8,1.9
                            c1.6,0.6,2.7,2.5,2.3,4.2l0,0C361.7,183.7,360.1,184.5,358.5,183.9z"/>
                    </g>
                    <path className="hand1" d="M251.5,204.2L251.5,204.2c-3.4-4.2-2.7-10.4,1.5-13.8l16.5-13.3c4.2-3.4,10.4-2.7,13.8,1.5l0,0
                        c3.4,4.2,2.7,10.4-1.5,13.8l-16.5,13.3C261.1,209,254.9,208.4,251.5,204.2z"/>
                    <path className="st12" d="M350.8,362.2l-63-36.3c-2.3-1.3-2-3.6,0.7-5.2l86.7-51.4c2.6-1.6,6.6-1.8,8.9-0.5l63,36.3
                        c2.3,1.3,2,3.6-0.7,5.2l-86.7,51.4C357,363.3,353,363.5,350.8,362.2z"/>
                    <path className="st18" d="M444,304c-0.2-0.2-0.5-0.5-0.8-0.7l-63-36.3c-2.3-1.3-6.2-1.1-8.9,0.5l-86.7,51.4c-0.3,0.2-0.6,0.4-0.8,0.6
                        l-1.4,0.7l0,2.1c0.1,0.7,0.6,1.4,1.5,1.9l63,36.3c2.3,1.3,6.2,1.1,8.9-0.5l86.7-51.4c1.4-0.8,2.1-1.8,2.2-2.8l0-1.7L444,304z"/>
                    <path className="st24" d="M346.9,358.7l-63-36.3c-2.3-1.3-2-3.6,0.7-5.2l86.7-51.4c2.6-1.6,6.6-1.8,8.9-0.5l63,36.3
                        c2.3,1.3,2,3.6-0.7,5.2l-86.7,51.4C353.1,359.8,349.1,360,346.9,358.7z"/>
                    <polygon className="st14" points="350.8,353.2 290.2,318.2 377.2,268 437.7,303 		"/>
                    <path className="st12" d="M308,329.3l2.1,1.2l0,0c-2.4,1.4-2.4,3.7,0,5.1l11.2,6.5c2.4,1.4,6.4,1.4,8.8,0l1.4,0.8
                        c2.5,1.5,6.7,1.5,9.2,0l0,0c2.5-1.5,2.5-3.8,0-5.3L317.2,324c-2.5-1.5-6.7-1.5-9.2,0l0,0C305.4,325.5,305.4,327.9,308,329.3z"/>
                    <path className="st11" d="M310.9,310.9c-2.7,1.6-2.8,4.1-0.2,5.5c1.5,0.8,3.5,1.1,5.4,1c-0.2,1.1,0.4,2.3,1.8,3.1
                        c2.6,1.5,7.1,1.2,9.8-0.5c0.5-0.3,1-0.6,1.5-1.2h0c0,0-0.2,0.2-0.2,0.1c0.4-0.5,0.6-0.8,0.6-1.3c0.9-3-0.9-7.6-0.9-7.6
                        c-10.2-1.6-14.3-0.4-14.3-0.4l-0.3,0.1C313.3,309.9,311.6,310.5,310.9,310.9z"/>
                    <path className="st11" d="M366.3,337.6l-27.7-16.5c-1-0.6-1-2,0-2.6l0,0c0.5-0.3,1.1-0.3,1.6,0l27.7,16.5c1,0.6,1,2,0,2.6l0,0
                        C367.4,337.9,366.8,337.9,366.3,337.6z"/>
                    <path className="st25" d="M397,317.9l-27.7-16.5c0,0-0.1-0.1-0.2-0.1l-22.3-13.2c-0.5-0.3-1.1-0.3-1.6,0c-1,0.6-0.9,2,0,2.6l27.7,16.5
                        c0,0,0.1,0.1,0.2,0.1l22.3,13.2c0.5,0.3,1.1,0.3,1.6,0C398,319.9,398,318.5,397,317.9z"/>
                    <path className="st25" d="M388,324.4l-27.7-16.5c0,0-0.1-0.1-0.2-0.1l-22.3-13.2c-0.5-0.3-1.1-0.3-1.6,0c-1,0.6-0.9,2,0,2.6l27.7,16.5
                        c0,0,0.1,0.1,0.2,0.1l22.3,13.2c0.5,0.3,1.1,0.3,1.6,0C389,326.4,388.9,324.9,388,324.4z"/>
                    <path className="st25" d="M378.9,330.8l-27.7-16.5c0,0-0.1-0.1-0.2-0.1L328.7,301c-0.5-0.3-1.1-0.3-1.6,0c-1,0.6-0.9,2,0,2.6
                        l27.7,16.5c0,0,0.1,0.1,0.2,0.1l22.3,13.2c0.5,0.3,1.1,0.3,1.6,0C379.9,332.8,379.9,331.4,378.9,330.8z"/>
                    <path className="st11" d="M360.9,342.4l-27.7-16.5c-1-0.6-1-2,0-2.6l0,0c0.5-0.3,1.1-0.3,1.6,0l27.7,16.5c1,0.6,1,2,0,2.6l0,0
                        C362,342.7,361.4,342.7,360.9,342.4z"/>
                    <path className="st11" d="M420.2,300.1l-13.7-8.1c-0.7-0.4-1.5-0.2-2,0.4v0c-0.6,0.7-0.4,1.8,0.4,2.3l13.8,8.2c0.6,0.4,1.5,0.3,2-0.3
                        C421.3,301.6,421.1,300.6,420.2,300.1z"/>
                    <path className="st11" d="M413.6,306.6l-20.9-12.4c-0.7-0.4-1.5-0.2-2,0.4l0,0c-0.6,0.7-0.4,1.8,0.4,2.3l21,12.5
                        c0.6,0.4,1.5,0.3,2-0.3C414.7,308.2,414.4,307.1,413.6,306.6z"/>
                    <path className="st12" d="M403.1,305.3l-0.2-4.9c0-1.1-1-2-2.1-1.9l-0.7,0c-0.7,0-1.3,0.4-1.7,1l-43.6,1.7c-1.2,0-2.2,0.6-2.8,1.5
                        l-0.1,0c-2.6-1-5.5-0.7-7.9,0.8l-2.5,1.6l-1.1,0.7c-0.2,0.1-0.2,0.4,0,0.5l1.2,0.6l3,1.3c2.4,1,5.1,1,7.4-0.2l0.6-0.3
                        c0.7,0.5,1.5,0.9,2.5,0.8l42.3-1.7c-0.2,0.9-0.6,1.7-1.2,2.3c-0.8,0.8-1.9,1.2-3,1.2l-18.8,0.6c-0.5,0-0.8,0.4-0.8,0.9
                        c0,0.5,0.4,0.8,0.9,0.8l18.9-0.6c1.7-0.1,3.2-0.8,4.3-2c0.8-0.9,1.3-2,1.4-3.1c0.4,0.3,0.8,0.4,1.3,0.4l0.7,0
                        C402.2,307.3,403.1,306.4,403.1,305.3z"/>
                    <g>
                        <path className="st18" d="M354.4,301l-1.2-0.5c-2.6-1-5.5-0.7-7.9,0.8l-2.5,1.6l-1.1,0.7c-0.2,0.1-0.2,0.4,0,0.5l1.2,0.6l3,1.3
                            c2.4,1,5.1,1,7.4-0.2l1.4-0.7L354.4,301z"/>
                        <path className="st18" d="M402.5,304.4l-46.2,1.8c-2,0.1-3.7-1.5-3.8-3.5l0,0c-0.1-2,1.5-3.7,3.5-3.7l45.9-1.8L402.5,304.4z"/>
                        <path className="st24" d="M399.5,303.2l-0.2-4.9c0-1.1,0.8-2,1.9-2.1l0.7,0c1.1,0,2,0.8,2.1,1.9l0.2,4.9c0,1.1-0.8,2-1.9,2.1l-0.7,0
                            C400.5,305.2,399.5,304.3,399.5,303.2z"/>
                        <g>
                            <g>
                                <path className="st18" d="M374.7,309.7L374.7,309.7c0-0.5,0.4-0.9,0.8-0.9l18.8-0.6c1.1,0,2.2-0.4,3-1.2c0.9-0.9,1.3-2,1.3-3.2
                                    l-0.2-6.1l1.7-0.1l0.2,5.9c0.1,1.6-0.5,3.2-1.5,4.4c-1.1,1.3-2.7,2-4.3,2l-18.9,0.6C375.1,310.5,374.7,310.1,374.7,309.7z"/>
                            </g>
                        </g>
                    </g>
                    <path className="st25" d="M308,327.9l2.1,1.2l0,0c-2.4,1.4-2.4,3.7,0,5.1l11.2,6.5c2.4,1.4,6.4,1.4,8.8,0l1.4,0.8
                        c2.5,1.5,6.7,1.5,9.2,0l0,0c2.5-1.5,2.5-3.8,0-5.3l-23.5-13.6c-2.5-1.5-6.7-1.5-9.2,0l0,0C305.4,324.1,305.4,326.5,308,327.9z"/>
                    <path className="st14" d="M314.4,328.4l17.2,10.2c0.6,0.4,0.6,1.2,0,1.6l0,0c-0.3,0.2-0.7,0.2-1,0L313.4,330c-0.6-0.4-0.6-1.2,0-1.6
                        l0,0C313.7,328.2,314.1,328.2,314.4,328.4z"/>
                    <path className="st26" d="M398,86.2c0,0,23.9,6,18.5,39.2c-5.3,33.3-7.1,81.6-7.1,81.6s0.7,23.8-14.1,30.9l-47.7-28
                        c0,0,16.3-19.3,15.3-58.1c-0.6-23,4.2-34.9,4.2-34.9l21.1-24.3L398,86.2z"/>
                    <g>
                        <g>
                            <path className="hand2" d="M392.6,169.1c0.9,15.9-4.3,30.1-16.3,40.9c-14.2,12.7-34.1,17.8-52.5,20.4c-4.6,0.6-7.1,6.5-6,10.5
                                c1.4,4.9,5.9,6.6,10.5,6c21.9-3,44.3-10.2,60.9-25.5c14.5-13.5,21.5-32.5,20.4-52.2C409.1,158.2,392,158.1,392.6,169.1
                                L392.6,169.1z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st14" d="M392.1,109.9c2.2,12.6,3.1,25.3,2.2,38.1c-0.9,12.6-3.7,24.9-6.6,37.2c-3.3,14.2,18.6,20.3,21.9,6
                                c3.3-14.2,6.4-28.6,7.4-43.3c1.1-14.8-0.5-29.5-3-44.1c-1-6.1-8.5-9.5-14-7.9C393.7,97.6,391.1,103.8,392.1,109.9L392.1,109.9z"
                                />
                        </g>
                    </g>
                    <path className="st14" d="M403.2,88.4c0,0-14.3,22.4-17.5,28.4l-14.3-0.6l8.8,10.3l-17.6,25.9c0,0-3.8-41.5,24.9-74.2L403.2,88.4z"/>
                    <path className="st26" d="M358,75.4c0,0-15.6,41.5-12.7,57.1c5.1,26.9-1.8,40.9-8.4,47.4c-6.6,6.5-16,14.8-16,14.8l-12.2-6.6
                        c0,0,29.3-12.9,29.1-26.8c-0.2-13.8-10.7-28.6-9.5-38.3c1.3-9.7,5.9-43.1,15.7-46.8C353.9,72.5,358,75.4,358,75.4z"/>
                    <path className="st14" d="M344.1,76.2c0,0-1.2,18.6-1.4,23.7l5,10l-8.2,10.8l7.8,24.3c0,0,5.6-44.7,9.7-73.1L344.1,76.2z"/>
                    <g>
                        <path className="st0" d="M383.7,78c3.2,3.4,5.8,7.4,4.3,12.2c-1.1,3.7-3.6,6.8-4.6,10.6c-1.9,7.5-0.7,15.4-1.3,23
                            c-0.2,2.3,3.3,2.6,3.5,0.3c0.8-10.1-0.9-19.7,4-29.1c3.8-7.3,2.4-13.4-3.2-19.3C384.9,74.2,382.2,76.4,383.7,78L383.7,78z"/>
                    </g>
                    <g>
                        <path className="st0" d="M386.1,93.6c-5.5,8.2-17.3,12.3-18,23.6c-0.1,2.3,3.4,2.6,3.5,0.3c0.6-10.2,12.3-14.4,17.3-21.8
                            C390.2,93.8,387.4,91.8,386.1,93.6L386.1,93.6z"/>
                    </g>
                    <path className="st11" d="M379.2,127.4l-14,23.3c-3.2,4.9-5.4,7.5-11.8,3.9c-0.5,0.9-0.9,1.8-1.3,2.7l1,0.6c4.6,2.8,10.6,1.3,13.3-3.2
                        l16.8-27.9C386,122.1,381.9,122.9,379.2,127.4z"/>
                    <path className="st0" d="M350.4,152.7l3.5,1.6c1,0.5,1.4,1.6,1,2.6l-0.2,0.5c-0.5,1-1.6,1.4-2.6,1l-3.5-1.6c-1-0.5-1.4-1.6-1-2.6
                        l0.2-0.5C348.3,152.7,349.5,152.3,350.4,152.7z"/>
                    <path className="st11" d="M371,123l-2.9,27c-0.4,5.9-0.2,9.3,7,10.2c-0.2,1-0.4,2-0.6,3l-1.1-0.1c-5.3-0.6-9.2-5.3-8.7-10.6l3.5-32.4
                        C368.8,114.7,371.6,117.8,371,123z"/>
                    <path className="st0" d="M378.6,160.6l-3.7-0.8c-1.1-0.2-2.1,0.4-2.3,1.5l-0.1,0.5c-0.2,1.1,0.4,2.1,1.5,2.3l3.7,0.8
                        c1.1,0.2,2.1-0.4,2.3-1.5l0.1-0.5C380.3,161.9,379.7,160.8,378.6,160.6z"/>
                    <path className="st0" d="M381.5,125.9l1.8,0.7c0.9,0.4,2-0.1,2.4-1l0.4-1c0.4-0.9-0.1-2-1-2.4l-1.8-0.7c-0.9-0.4-2,0.1-2.4,1l-0.4,1
                        C380.1,124.5,380.5,125.5,381.5,125.9z"/>
                    <path className="st0" d="M368.8,120.9l1.9,0.4c1,0.2,2-0.5,2.2-1.5l0.2-1c0.2-1-0.5-2-1.5-2.2l-1.9-0.4c-1-0.2-2,0.5-2.2,1.5l-0.2,1
                        C367.1,119.7,367.7,120.7,368.8,120.9z"/>
                    <g>
                        <g>
                            <path className="st0" d="M345.3,125.3c1.1-11.6,1.4-23.3,3.3-34.8c1.2-7.5,3.4-16.5,12.1-18.2c1.7-0.3,1.5-3.1-0.2-2.7
                                c-8,1.6-11.6,7.8-13.5,15.2c-3.2,12.9-3,26.8-4.3,40C342.4,126.5,345.1,127.1,345.3,125.3L345.3,125.3z"/>
                        </g>
                    </g>
                    <path className="st11" d="M336.4,126.4c-1.5,3.8,0.4,8.1,4.2,9.6c3.8,1.5,8.1-0.4,9.6-4.2c1.5-3.8-0.4-8.1-4.2-9.6
                        C342.1,120.8,337.9,122.6,336.4,126.4z"/>
                    <path className="st27" d="M338.1,125.5c-1.1,2.9,0.3,6.2,3.2,7.4c2.9,1.1,6.2-0.3,7.4-3.2c1.1-2.9-0.3-6.2-3.2-7.4
                        C342.6,121.1,339.3,122.6,338.1,125.5z"/>
                    <path className="st0" d="M338.4,123.3c-1.1,2.9,0.3,6.2,3.2,7.4c2.9,1.1,6.2-0.3,7.4-3.2c1.1-2.9-0.3-6.2-3.2-7.4
                        C342.9,118.9,339.6,120.3,338.4,123.3z"/>
                    <g>
                        <g>
                            <path className="st28" d="M338.6,17.6c-1.1,8.2-6.4,15-7.9,23.1c-0.6,3.2-0.8,7,0.8,10c2.3,4.4,5.2,6.6,5.1,12
                                c-0.1,3.7,5.6,3.7,5.7,0c0.1-3.5-0.6-6.9-2.3-10c-1-1.7-3.1-3.5-3.7-5.4c-0.7-2-0.2-4.6,0.2-6.5c1.8-7.5,6.5-13.9,7.6-21.7
                                C344.6,15.6,339.1,14,338.6,17.6L338.6,17.6z"/>
                        </g>
                    </g>
                    <ellipse transform="matrix(0.4806 -0.877 0.877 0.4806 184.9714 357.3015)" className="st28" cx="394.1" cy="22.5" rx="20" ry="20"/>
                    <path className="st11" d="M403.4,20.5c2.7,10.7,2.1,20.6-8.6,23.4s-27.3-2.8-30.1-13.5c-2.7-10.7,6.2-23.4,16.8-26.1
                        S400.7,9.9,403.4,20.5z"/>
                    <path className="st7" d="M364.9,77.1l-6.7,0.4c-10.4,0.6-19.4-7.5-19.9-17.9l-1.9-34.3c-0.6-10.4,7.5-19.4,17.9-19.9L361,5
                        c10.4-0.6,19.4,7.5,19.9,17.9l1.9,34.3C383.3,67.6,375.3,76.5,364.9,77.1z"/>
                    <path className="st28" d="M368.9,33.2c-8.1-9.5-22.3,2.3-30.2-0.5c-8.6-3.1-5.9-11.9-0.2-19.1c3-3.9,6.9-7.1,14.7-10.3
                        c21.8-8.8,44.1,0.1,45.4,24.9c0,0,2.7,34-17,39.5l-1.4-5c0,0,6.9-10.3-5.4-17.4c-2.9-1.7-0.6-2.9-0.4-7
                        C374.5,34.1,371.6,36.4,368.9,33.2z"/>
                    <path className="st7" d="M386.6,57.8c-3,2.6-6.5,1.8-7.8-1.8c-1.3-3.6,0-8.6,3-11.1c3-2.6,6.5-1.8,7.8,1.8
                        C390.9,50.3,389.6,55.3,386.6,57.8z"/>
                    <path className="st14" d="M344.3,58.2l11.8,4.8c0.5,0.2,0.8,0.7,0.7,1.2c-0.2,1.9-1.5,5.8-7.9,3.7c-4.5-1.5-5.8-5.8-6.2-8.4
                        C342.5,58.6,343.4,57.9,344.3,58.2z"/>
                    <path className="st29" d="M361.4,51.4c0,1.5-0.5,2.7-1.3,2.7c-0.7,0-1.3-1.2-1.4-2.6c0-1.5,0.5-2.7,1.3-2.7
                        C360.8,48.8,361.4,50,361.4,51.4z"/>
                    <path className="st29" d="M344.1,44.9c0,1.5-0.5,2.7-1.3,2.7c-0.7,0-1.3-1.2-1.4-2.6c0-1.5,0.5-2.7,1.3-2.7
                        C343.4,42.3,344,43.4,344.1,44.9z"/>
                    <g>
                        <path className="st28" d="M358.1,43.5c2.6,0,5.9,1.5,7.7,3.3c1.3,1.3,3.3-0.8,2-2.1c-2.3-2.3-6.5-4.1-9.8-4.1
                            C356.2,40.7,356.3,43.5,358.1,43.5L358.1,43.5z"/>
                    </g>
                    <g>
                        <path className="st28" d="M338.9,36.7c2.6-0.2,6,0.9,8,2.6c1.4,1.2,3.4-0.9,2-2.1c-2.5-2.2-6.7-3.7-10-3.4
                            C337,34,337.1,36.8,338.9,36.7L338.9,36.7z"/>
                    </g>
                    <g>
                        <g>
                            <path className="st28" d="M369.7,28.7c0.3,8.9,1.5,18.3,4.7,26.6c1.6,4,4,6.9,6.9,10.1c1.8,2,4,4.3,4.1,7.2c0.1,3.2-1.7,6.4-1.8,9.6
                                c-0.2,3.7,5.5,3.6,5.7,0c0.3-5.4,3.2-9.5,0.6-14.8c-2.1-4.4-6.4-7.2-8.8-11.3c-4.5-7.8-5.4-18.6-5.7-27.4
                                C375.2,25.1,369.5,25.1,369.7,28.7L369.7,28.7z"/>
                        </g>
                    </g>
                    <path className="st14" d="M132.6,176.1l-15.7-9.6c-1.8-1.1-1.5-2.9,0.6-4.1l0,0c2.1-1.2,5.3-1.2,7.1-0.1l15.7,9.6
                        c1.8,1.1,1.5,2.9-0.6,4.1l0,0C137.6,177.2,134.4,177.2,132.6,176.1z"/>
                    <g>
                        <path className="st30" d="M399.8,286.2c-0.3-0.2-0.5-0.3-0.8-0.5c2.9-3.4-1.5-8.6-3.1-10.3c-0.3-0.4-0.8-0.6-1.3-0.6
                            c-2-0.2-7.5-0.6-13.5,1c-3.2,0.3-7.7,0.8-12.1,2.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0-1.4,0-2.1,0.1c-5.1,0.3-9,1.2-11.5,2
                            c-1.5,0.4-2.7,0.9-3.6,1.3c-1.2,0.5-1.6,2-0.9,3.1c1,1.4,2.5,3.3,4.7,4.9c-0.4,1.7-0.5,3.5-0.2,5.4c0.3,1.4,1.6,2.3,2.9,2.6
                            c0.8,0.2,1.5,0.1,2-0.2c0.4-0.2,0.6-0.6,0.6-1.1c0,0,0-1.7,0.3-3.9c2,0.5,4.2,0.7,6.6,0.4c12.5-1.6,6.6-10.7,6.6-10.7l0.3-0.1
                            l0.9-0.2l0.3-0.1c0,0,6,9.7,19.1,6.2c0.1,0,0.3-0.1,0.4-0.1c1.4,0.7,2.2,1.2,2.3,1.2c1.6,0.9,3,0.8,3.6,0.4
                            c0.1,0,0.1-0.1,0.2-0.1C401.6,288.8,401.5,287.3,399.8,286.2z M396.9,284.1c0.1-0.2,0.2-0.4,0.3-0.7
                            C397.1,283.6,397,283.9,396.9,284.1z"/>
                        <g>
                            <path className="st18" d="M350.4,293c-0.7-4.5,0.5-8.4,3.6-11.5c2.4-2.5,6.1-4.4,10.8-5.8c8-2.4,16.5-2.4,16.6-2.4l0.4,0.7
                                c-0.1,0-10.2,1.8-19.9,7c-6,3.2-5.8,13.2-5.8,13.3c0,0.5-0.2,0.9-0.6,1.2c-0.5,0.3-1.3,0.4-2.1,0.2
                                C352.1,295.3,350.7,294.4,350.4,293z"/>
                            <path className="st18" d="M362,275.9c5.5-0.3,10.8,0.1,16,1.2c6.5,1.4,12.5,3.3,18.1,6.8c1.7,1.1,1.9,2.7,1.3,3.3
                                c0,0-0.1,0.1-0.2,0.1c-0.7,0.5-2.1,0.5-3.7-0.4c-0.1-0.1-13.3-7.4-22.2-8c-14.4-1.1-24.4,0.5-24.5,0.5l0.2-0.2
                                C347.2,279.2,352.8,276.4,362,275.9z"/>
                            <path className="st31" d="M348.2,281c0,0-0.4,5,4.7,6.8c5.1,1.8,12.2,2.9,14.6,0.1c2.4-2.9,3.1-7.7,0.8-9.6
                                C366.1,276.5,352.7,276.3,348.2,281z"/>
                            <path className="st31" d="M372.6,276.9c0,0-0.4,5,4.7,6.8c5.1,1.8,12.2,2.9,14.6,0.1c2.4-2.9,3.1-7.7,0.8-9.6
                                C390.5,272.4,377.1,272.1,372.6,276.9z"/>
                            <path className="st18" d="M346.7,279.3c-1.2,0.6-1.6,2.1-0.9,3.2c2.3,3.3,7.9,9.5,17.3,8.3c12.7-1.7,6.8-10.8,6.8-10.8l0.3-0.1
                                l0.9-0.2l0.3-0.1c0,0,6.1,9.8,19.5,6.3c10.2-2.7,3.4-10.7,1.3-12.9c-0.4-0.4-0.8-0.6-1.3-0.6c-2.9-0.3-13.6-0.9-22.3,4.8l-0.1,0
                                C356.5,275.8,349.8,277.9,346.7,279.3z M374.9,279.8L374.9,279.8c-1-1.1-0.6-2.8,0.7-3.4c5.7-2.6,11.7-2.3,13.9-2.1
                                c0.5,0.1,1,0.3,1.4,0.7c1.6,1.9,5.7,7.4-1.6,9C380.4,285.9,374.9,279.8,374.9,279.8z M349.8,283.8c-1.1-1.1-0.7-3,0.8-3.5
                                c2.6-1,7.2-2,14.4-1.4c0.6,0.1,1.2,0.4,1.5,0.9l1,1.3c0,0,4.7,6.2-4.7,7.5C356.5,289.3,352.1,286.1,349.8,283.8z"/>
                            <path className="st14" d="M352.8,285L352.8,285c-0.3-0.1-0.5-0.5-0.4-0.8l1.3-3.7c0.1-0.3,0.5-0.5,0.8-0.4l0,0
                                c0.3,0.1,0.5,0.5,0.4,0.8l-1.3,3.7C353.5,285,353.2,285.1,352.8,285z"/>
                            <path className="st14" d="M355.2,285.1L355.2,285.1c-0.2-0.1-0.3-0.3-0.3-0.6l1.4-4.1c0.1-0.2,0.3-0.3,0.6-0.3l0,0
                                c0.2,0.1,0.3,0.3,0.3,0.6l-1.4,4C355.7,285.1,355.4,285.2,355.2,285.1z"/>
                            <path className="st14" d="M378.2,280.7L378.2,280.7c-0.3-0.1-0.5-0.5-0.4-0.8l1.3-3.7c0.1-0.3,0.5-0.5,0.8-0.4l0,0
                                c0.3,0.1,0.5,0.5,0.4,0.8l-1.3,3.7C378.9,280.6,378.6,280.8,378.2,280.7z"/>
                            <path className="st14" d="M380.6,280.8L380.6,280.8c-0.2-0.1-0.3-0.3-0.3-0.6l1.4-4c0.1-0.2,0.3-0.3,0.6-0.3l0,0
                                c0.2,0.1,0.3,0.3,0.3,0.6l-1.4,4.1C381.1,280.7,380.8,280.8,380.6,280.8z"/>
                        </g>
                    </g>
                    <path className="st1" d="M234.2,161.6l-49.9-30.4l-80.5,42.2l0.2,0.1l-3.1,1.6l54.3,33.4c1,0.6,2.2,0.6,3.3,0.1l72.6-39.6
                        c0.3-0.2,0.5-0.3,0.7-0.5l2.2-1.2C236.2,166,236.3,162.9,234.2,161.6z"/>
                    <path className="st32" d="M152,206.8l-54.4-33.4l80.5-42.2l49.9,30.4c2.1,1.3,2.1,4.5-0.1,5.7l-72.6,39.6
                        C154.3,207.4,153,207.4,152,206.8z"/>
                    <path className="st18" d="M101.7,171.5l-4,1.8c-1.8-4.1-0.6-10.1-0.6-10.1c0.5-1.9,2.5-3.1,4.4-2.6l1.9,0.5c1.9,0.5,3.1,2.5,2.6,4.4
                        l-0.7,2.8C104.8,170.3,103.6,172,101.7,171.5z"/>
                    <path className="st14" d="M151,202.3l-48.8-29.8c-1.6-1-1.5-3.3,0.1-4.2l75.5-41.2l47.8,29.1c3.1,1.9,3,6.4-0.2,8.1l-69.7,38
                        C154.2,203.2,152.4,203.2,151,202.3z"/>
                    <path className="st14" d="M151,202.3l-47.4-28.9c-2.2-1.3-2.1-4.6,0.1-5.8l74.1-40.4l47.8,29.1c3.1,1.9,3,6.4-0.2,8.1l-69.7,38
                        C154.2,203.2,152.4,203.2,151,202.3z"/>
                    <path className="st18" d="M99.3,164.4l-0.7-0.4c-1.5-0.9-1.2-2.4,0.5-3.3l72.9-39.8c1.7-0.9,4.3-1,5.8-0.1l0.7,0.4
                        c1.5,0.9,1.2,2.4-0.5,3.3l-72.9,39.8C103.4,165.2,100.8,165.3,99.3,164.4z"/>
                    <path className="st32" d="M152.8,197l-53.1-32.4l79.2-43.2l49.9,30.4c2.1,1.3,2.1,4.5-0.1,5.7l-72.6,39.6
                        C155.1,197.6,153.8,197.6,152.8,197z"/>
                    <path className="st33" d="M177.1,117.2l45.7,30.3c3.4,2.2,4.4,4.7,2.5,5.8l-65,38.5c-2.4,1.4-8.3,0.1-12.4-2.6l-45.7-30.3
                        c-3.4-2.2-4.4-4.7-2.5-5.8l65-38.5C167.1,113.2,173,114.4,177.1,117.2z"/>
                    <path className="st0" d="M215.7,151.6l-46.9-31c-0.9-0.6-3-0.7-3.9-0.2L108,153.8c-0.4,0.3-0.4,0.6,0,0.9l46.9,31
                        c0.9,0.6,3,0.7,3.9,0.2l56.9-33.4C216.2,152.3,216.2,151.9,215.7,151.6z"/>
                    <g>
                        <path className="st34" d="M164.3,152.5l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C165.1,154,165.2,153.1,164.3,152.5z"
                            />
                        <path className="st34" d="M168.6,149.9l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C169.4,151.3,169.5,150.4,168.6,149.9
                            z"/>
                        <path className="st34" d="M160,155.1l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C160.8,156.6,160.9,155.7,160,155.1z"/>
                        <path className="st34" d="M155.7,157.8l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C156.5,159.2,156.6,158.3,155.7,157.8
                            z"/>
                        <path className="st34" d="M151.4,160.4l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C152.2,161.9,152.3,161,151.4,160.4z"
                            />
                        <path className="st34" d="M147.6,162.8l-1.4-0.9c-1-0.7-2.8-0.7-4,0l-2.2,1.3l4.8,3.1l2.6-1.6C148.4,164.3,148.4,163.4,147.6,162.8z"
                            />
                        <path className="st34" d="M173.6,147l-1.7-1.1c-1.2-0.8-3.3-0.8-4.7,0.1l-0.3,0.2l5.8,3.7l0.7-0.4C174.6,148.8,174.7,147.7,173.6,147
                            z"/>
                        <path className="st34" d="M178.4,144.3l-1.9-1.2c-1.4-0.9-3.7-0.9-5.2,0.1l-0.3,0.2l6.5,4.2l0.8-0.5
                            C179.4,146.3,179.5,145,178.4,144.3z"/>
                        <path className="st34" d="M183.1,141.3l-1.9-1.2c-1.4-0.9-3.7-0.9-5.2,0.1l-0.3,0.2l6.5,4.2l0.8-0.5
                            C184.2,143.3,184.3,142.1,183.1,141.3z"/>
                        <path className="st34" d="M188.2,138.6l-2.3-1.5c-1.7-1.1-4.5-1-6.3,0.1l-0.4,0.3l7.8,5l0.9-0.6C189.5,141,189.6,139.5,188.2,138.6z"
                            />
                        <g>
                            <path className="st34" d="M145.2,170.2c1,0.3,2.6,1,3.3,1.4c3.9,2.8,3.5,4.9,6.6,7c0.6,0.4,1.5,0.7,2.7,0.5c1.1-0.2,1.9-0.9,2.2-1.4
                                c0.2-0.5-0.1-0.9-0.4-1.3l0,0c-1.6-1.7-4.3-3-6.9-4.3c-1.3-0.6-2.6-1.3-3.5-2.1c-0.5-0.5-0.7-1.4-1.8-1.6
                                c-0.6-0.1-1.3,0.1-1.9,0.4C144.4,169.4,144.5,170,145.2,170.2z"/>
                        </g>
                        <g>
                            <path className="st34" d="M134.1,163.1c-0.4-0.6-1.5-1.7-2.2-2.1c-4.3-2.5-7.7-2.3-11-4.3c-0.6-0.4-1.1-0.9-0.7-1.7
                                c0.4-0.7,1.4-1.2,2.2-1.3c0.8-0.1,1.5,0.1,2.1,0.3l0,0c2.8,1,4.7,2.8,6.8,4.4c1,0.8,2,1.7,3.2,2.3c0.7,0.3,2.2,0.5,2.5,1.2
                                c0.1,0.4-0.2,0.8-0.7,1.2C135.4,163.6,134.4,163.6,134.1,163.1z"/>
                        </g>
                        <g>
                            <path className="st34" d="M158,170.7c6.4,0.4,11.5-0.3,16.4-2.9c4.4-2.3,8-4.8,11.3-8.1c1.6-1.5,2.7-3.2,3.3-5
                                c0.6-1.6,0-3.7-3.8-4.1c-1.5-0.2-3,0.6-4.2,1c-3.2,1-5.7,1.8-9.5,1.7c-0.7,0-0.8-0.7,0-0.7c4.6,0.1,7.1-1.3,11-2.3
                                c1.7-0.4,3.9-0.8,5.7-0.2c4.3,1.4,1.9,5.2,0.8,7.1c-2.6,4.4-7.2,8.7-12.9,11.6c-5.2,2.7-11.3,2.9-18.1,2.5
                                C157.3,171.4,157.2,170.7,158,170.7L158,170.7z"/>
                        </g>
                        <g>
                            <path className="st34" d="M155.9,163.8c4.6,1.8,6.5,3.9,8.2,7c0.2,0.4-1.9,0.9-2,0.4c-0.8-2.8-2.3-5.2-6.7-6.8
                                C154.8,164.1,155.3,163.5,155.9,163.8L155.9,163.8z"/>
                        </g>
                        <g>
                            <path className="st34" d="M159.7,161.5c5.1,2,8.9,5,10.8,8.5c0.2,0.4-2.2,1-2.3,0.5c-0.6-2.6-4.2-6.5-9-8.4
                                C158.6,161.8,159.1,161.2,159.7,161.5L159.7,161.5z"/>
                        </g>
                        <g>
                            <path className="st34" d="M163.4,159.7c5.6,1.7,10.6,4.4,13.4,8c0.3,0.4-1.2,1.2-1.5,0.8c-2.7-3.4-7-6.5-12.4-8.1
                                C162.2,160.1,162.7,159.5,163.4,159.7L163.4,159.7z"/>
                        </g>
                        <g>
                            <path className="st34" d="M165.7,156.9c6,1.8,12.5,4.6,14.6,8.8c0.2,0.4-1.6,1.3-1.8,0.9c-1.9-4-7.6-7.4-13.3-9.1
                                C164.5,157.3,165.1,156.7,165.7,156.9L165.7,156.9z"/>
                        </g>
                        <g>
                            <path className="st34" d="M168.6,154.6c6.3,1.1,12.4,3.8,16.3,7.2c0.4,0.4-1.4,1.5-1.9,1.1c-3.7-3.2-8.7-6.6-14.7-7.6
                                C167.6,155.1,167.9,154.5,168.6,154.6L168.6,154.6z"/>
                        </g>
                        <g>
                            <path className="st34" d="M171.6,152.8c5.9,1,11.7,2.8,15.5,6c0.4,0.4-0.8,1.6-1.3,1.2c-3.6-3.1-8.9-5.5-14.5-6.5
                                C170.6,153.3,170.9,152.6,171.6,152.8L171.6,152.8z"/>
                        </g>
                        <g>
                            <path className="st34" d="M178.2,151.9c2.1,0.2,4.2,0.4,6.2,1c2.1,0.6,3.6,1.7,5.1,2.8c0.5,0.4-0.5,1.4-0.9,1.1
                                c-1.3-1-2.5-2.3-4.3-2.9c-1.9-0.6-4-1-6-1.2C177.5,152.6,177.5,151.9,178.2,151.9L178.2,151.9z"/>
                        </g>
                        <g>
                            <path className="st34" d="M133.6,155.1c-0.5-4,0.8-7.2,4.9-10.2c3.7-2.7,7.8-4.9,13.1-6.9c2.5-0.9,5.2-1.6,8.1-2
                                c2.6-0.3,5.9,0.1,6.4,2.5c0.2,0.9-1.1,1.9-1.8,2.6c-1.7,2-3,3.6-2.9,5.9c0,0.5,1.1,0.5,1.1,0c-0.1-2.9,2.3-4.4,3.9-6.8
                                c0.7-1,1.4-2.4,0.5-3.6c-2.2-2.7-8.3-1.3-11.3-0.7c-7,1.5-14,4.3-18.8,7.8c-4.4,3.2-4.9,7-4.4,11.3
                                C132.6,155.5,133.7,155.6,133.6,155.1L133.6,155.1z"/>
                        </g>
                        <g>
                            <path className="st34" d="M144.7,156.6c-2.7-2.9-6.1-4.2-11-5.3c-0.7-0.2-1.4,1.2-0.7,1.3c4.4,0.6,8.2,1.6,10.7,4.3
                                C144.1,157.3,145,157,144.7,156.6L144.7,156.6z"/>
                        </g>
                        <g>
                            <path className="st34" d="M148.5,154.2c-3.1-3.3-7.7-5.7-13.3-7c-0.7-0.2-1.6,1.4-0.9,1.4c4.2,0.4,10.3,2.8,13.2,5.9
                                C147.9,155,148.8,154.6,148.5,154.2L148.5,154.2z"/>
                        </g>
                        <g>
                            <path className="st34" d="M151.4,152c-2.5-3.6-6.8-6.8-12.4-8.6c-0.7-0.2-1.9,0.7-1.2,0.9c5.4,1.8,10.2,4.5,12.7,8
                                C150.7,152.7,151.7,152.4,151.4,152L151.4,152z"/>
                        </g>
                        <g>
                            <path className="st34" d="M155.9,150.6c-2.7-3.8-7-8-13.6-9.3c-0.7-0.1-2.2,1-1.5,1.1c6.3,1.3,11.6,4.9,14.1,8.5
                                C155.3,151.3,156.2,151,155.9,150.6L155.9,150.6z"/>
                        </g>
                        <g>
                            <path className="st34" d="M159.6,148.8c-1.6-4-5.8-7.9-11.1-10.4c-0.6-0.3-2.4,0.9-1.8,1.1c5,2.4,10.3,5.6,11.8,9.4
                                C158.7,149.4,159.8,149.3,159.6,148.8L159.6,148.8z"/>
                        </g>
                        <g>
                            <path className="st34" d="M162.6,147c-1.5-3.7-4.1-7.4-9.2-9.9c-0.6-0.3-2.5,0.5-1.9,0.8c4.8,2.3,8.6,5.7,10,9.2
                                C161.7,147.6,162.8,147.4,162.6,147L162.6,147z"/>
                        </g>
                        <g>
                            <path className="st34" d="M164.1,142.9c-0.3-1.3-0.5-2.7-1.4-3.9c-1-1.3-2.6-2.3-4.3-3.3c-0.6-0.3-2.3,0.3-1.7,0.6
                                c1.5,0.8,3.6,1.6,4.5,2.8c1,1.2,1.6,2.5,1.8,3.8C163.1,143.3,164.2,143.3,164.1,142.9L164.1,142.9z"/>
                        </g>
                        <g>
                            <path className="st34" d="M168.4,174.7c0.1,0.2,0.2,0.4-0.1,0.8c-0.3,0.4-0.9,0.9-1.7,1.3c-1.4,0.6-2.2,0.5-2.6,0.3l0,0
                                c-1.2-0.5-2.3-1-3.5-1.5c-0.4-0.2-2.5-0.9,0.7-2.2c1.7-0.7,3.9-0.1,4.6,0C167.1,173.6,168,174,168.4,174.7z"/>
                        </g>
                        <g>
                            <path className="st34" d="M126.9,148.1c-0.3-0.1-0.7-0.1-1.3,0c-0.6,0.2-1.4,0.6-2,1.1c-1,0.8-0.9,1.4-0.6,1.6l0,0
                                c0.8,0.7,1.6,1.5,2.4,2.2c0.3,0.3,1.3,1.6,3.5-0.4c1.2-1,0.2-2.5,0.1-2.9C128.7,149,128,148.3,126.9,148.1z"/>
                        </g>
                        <path className="st34" d="M151.9,162.8L146,159c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C152.1,163.1,152.1,162.9,151.9,162.8z"/>
                        <path className="st34" d="M140.2,167.3l-1.4-0.9c-1-0.7-2.8-0.6-3.9,0l-0.2,0.2l4.8,3.1l0.6-0.4C141,168.7,141.1,167.8,140.2,167.3z"
                            />
                        <path className="st34" d="M140.7,169.7l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C140.9,169.9,140.9,169.8,140.7,169.7z"/>
                        <path className="st34" d="M156.2,160.1l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C156.4,160.4,156.4,160.2,156.2,160.1z"/>
                        <path className="st34" d="M160.5,157.5l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C160.7,157.8,160.7,157.6,160.5,157.5z"/>
                        <path className="st34" d="M164.8,154.9l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C165,155.1,165,155,164.8,154.9z"/>
                        <path className="st34" d="M169.1,152.2l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C169.3,152.5,169.3,152.3,169.1,152.2z"/>
                        <path className="st34" d="M173.4,149.6l-5.9-3.8c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l5.9,3.8c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C173.6,149.9,173.6,149.7,173.4,149.6z"/>
                        <path className="st34" d="M178.2,147.3l-6.9-4.4c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l6.9,4.4c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C178.4,147.5,178.4,147.4,178.2,147.3z"/>
                        <path className="st34" d="M183.3,145.2l-8.4-5.4c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l8.4,5.4c0.2,0.1,0.4,0.1,0.6,0
                            l0,0C183.5,145.4,183.5,145.3,183.3,145.2z"/>
                        <path className="st34" d="M188.3,142.9l-10-6.4c-0.2-0.1-0.4-0.1-0.6,0l0,0c-0.2,0.1-0.2,0.3,0,0.4l10,6.4c0.2,0.1,0.4,0.1,0.6,0l0,0
                            C188.4,143.2,188.4,143,188.3,142.9z"/>
                        <path className="st34" d="M146.1,167.2c1-0.6,0.9-1.6-0.1-2.2c-1-0.6-2.6-0.7-3.6-0.1c-1,0.6-0.9,1.6,0.1,2.2
                            C143.5,167.8,145.1,167.8,146.1,167.2z"/>
                        <path className="st34" d="M142.6,165c1-0.6,0.9-1.6-0.1-2.2c-1-0.6-2.6-0.7-3.6-0.1c-1,0.6-0.9,1.6,0.1,2.2
                            C140.1,165.6,141.7,165.6,142.6,165z"/>
                    </g>
                    <path className="st35" d="M170.7,293.8L148.6,281c-1.8-1-1.7-2.7,0.1-3.7l31.3-16.6c1.7-0.9,4.4-0.9,6.1,0.1l19.9,11.5
                        c1.7,1,1.7,2.5,0.1,3.5L177,293.8C175.3,294.8,172.5,294.8,170.7,293.8z"/>
                    <path className="st36" d="M170.7,290.8L148.6,278c-1.8-1-1.7-2.7,0.1-3.7l31.3-16.6c1.7-0.9,4.4-0.9,6.1,0.1l19.9,11.5
                        c1.7,1,1.7,2.5,0.1,3.5L177,290.8C175.3,291.8,172.5,291.8,170.7,290.8z"/>
                    <path className="st14" d="M239,209.1l-0.6,0.3l0,0l-110.2-66.3l-1-0.8c-0.8-0.5-1.3-0.2-2.5,0.7l-4.2,3.3l11.7,95.8
                        c0.1,1.1,0.5,2.4,1.4,3c0,0,111.4,56.6,111.4,55.5l-4.1-89l0-0.2C240.8,210.4,239.8,209.6,239,209.1z"/>
                    
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-327.2724" y1="283.1642" x2="-281.31" y2="283.1642" gradientTransform="matrix(0.9994 3.600000e-02 5.350000e-02 1.0026 508.895 -14.5192)">
                            <stop className="stop1"  offset="3.940890e-02"/>
                            <stop className="stop2"   offset="0.3172"/>
                            <stop className="stop3"   offset="0.5068"/>
                            <stop className="stop4"   offset="0.6701"/>
                            <stop className="stop5"   offset="0.8186"/>
                            <stop className="stop6"   offset="0.9556"/>
                            <stop className="stop7"  offset="1"/>
                        </linearGradient>
                    <path className="st37" d="M196.3,245.6c13.1-13.5,28.6,35.2,38.6,57.3c3.6,8.1,7.3,5.5,9,3.6c0.7-0.8,1-1.8,1-2.9l-4.5-91.5l0-0.4
                        c0-0.5-0.4-1.4-0.7-1.9L196.3,245.6z"/>
                    <path className="st12" d="M238.5,308.4l-111.3-66c-0.9-0.5-1.7-1.8-1.8-2.8l-4.9-91.6c-0.1-1,0.7-1.4,1.6-0.9l111.3,66
                        c0.9,0.5,1.7,1.8,1.8,2.8l4.9,91.6C240.2,308.5,239.4,308.9,238.5,308.4z"/>
                    <path className="st12" d="M237.9,295.8L126.1,229c-0.8-0.5-1.6-1.7-1.6-2.7l-4.4-79.4c-0.1-1,0.6-1.3,1.4-0.8l111.8,66.7
                        c0.8,0.5,1.6,1.7,1.6,2.7l4.4,79.4C239.4,295.9,238.8,296.3,237.9,295.8z"/>
                    <path className="st38" d="M170,293.9l-20.9-12.1c-2-1.2-3.1-3.4-2.7-5.7l3.8-23.7c0.7-4.3,5.4-6.6,9.2-4.6l19.5,10.4
                        c2.5,1.4,3.9,4.2,3.4,7.1l-4.8,25.3C176.8,293.8,173,295.6,170,293.9z"/>
                    <path className="st38" d="M174.2,273.8l-21.7-12.6c-2.1-1.2-3.2-3.6-2.8-6l3.9-24.6c0.7-4.4,5.6-6.8,9.5-4.7l20.2,10.8
                        c2.6,1.4,4.1,4.4,3.5,7.3l-5,26.2C181.1,273.8,177.2,275.6,174.2,273.8z"/>
                    <polygon className="st0" points="75.6,175.5 17.3,209.4 58.5,233 116.8,199.1 		"/>
                    <path className="st14" d="M75.3,171.9L17,205.8l41.2,23.6l58.3-33.9L75.3,171.9z"/>
                    <polygon className="st36" points="105.2,196.3 72.7,177.7 75.6,176 108.1,194.6 		"/>
                    <polygon className="st36" points="73.7,195.9 57.5,186.6 60.3,184.9 76.6,194.2 		"/>
                    <polygon className="st36" points="66.1,200.3 49.9,191 52.7,189.3 69,198.6 		"/>
                    <polygon className="st36" points="57.4,205.4 41.1,196.1 44,194.4 60.2,203.7 		"/>
                    <polygon className="st36" points="60.1,226 42.3,215.8 44.8,214.4 62.5,224.5 		"/>
                    <polygon className="st36" points="104.3,202.1 64.2,179.1 65.9,178.1 106,201.1 		"/>
                    <polygon className="st36" points="68.2,223.1 28.1,200.1 29.8,199.1 69.9,222.1 		"/>
                </g>
            </svg>
        </Container>
    );
}
 
export default ProcessIllustration;