import React, { PureComponent } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ProcessIllustration from "./illustrations/processIllustration"

const STEPS = [
  {
    title: "Create your Practice",
    description:
      "With Submitt you can keep all your patients and claims in the cloud, safe and secure, forever. Start by creating your account, adding your doctors, assistants and 3rd-party bureaus.",
  },
  {
    title: "Add Patients and Claim",
    description:
      "Add your patients to Submitt and start creating claims with super ease. Data is securely stored and accessible 24/7/365.",
  },
  {
    title: "Invite Doctors and Assistants",
    description:
      "Invite and add all your doctors and assistants to Submitt and watch the monotony of admin disappear.",
  },
]

const Container = styled.section`
  display: grid;
  grid-template: 1fr auto / 1fr;
  align-items: center;
  padding: var(--gutter-m) var(--gutter-s);
  @media (min-width: 768px) {
    grid-template: 1fr / 1fr 1fr;
    padding: var(--gutter-l) var(--gutter-l) 0;
  }
`

const Button = styled(Link)`
  background: rgb(80, 125, 220);
  background: linear-gradient(
    90deg,
    rgba(80, 125, 220, 1) 35%,
    rgba(97, 160, 255, 1) 100%
  );
  color: var(--white) !important;
  padding: 0.75rem 2rem !important;
  border: none;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.9rem;
  transition: all 250ms ease-out;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:after {
    content: none !important;
  }
`

const StepHero = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
    max-width: 300px;
  }
  @media (min-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    div {
      max-width: 550px;
    }
  }
`

const InfoBlock = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  padding-bottom: var(--gutter-m);
  @media (min-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    padding-bottom: 0;
  }
`

const StepInfo = styled.div`
  padding-bottom: 2rem;
  transition: 250ms ease-out;
  opacity: 0;
  h4,
  p {
    color: var(--manatee);
  }
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    line-height: 150%;
  }
  p {
    font-size: 1rem;
    line-height: 150%;
  }
  &.active {
    opacity: 1;
  }
  @media (min-width: 768px) {
    padding-bottom: 2rem;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  span {
    color: var(--purple);
    min-width: 25px;
    text-align: center;
    &.line {
      height: 2px;
      background-color: var(--purple);
      width: 75px;
      margin: 0 0.5rem;
    }
  }
`

const Arrows = styled.div`
  svg {
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0.25;
    transition: 250ms ease-out;
    path {
      fill: var(--purple);
    }
    &.prev {
      margin-right: 1rem;
      transform: scale(-1, -1);
    }
    &.active {
      opacity: 1;
    }
  }
`

class Process extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
    }
  }
  renderStepInfo = () => {
    let { activeStep } = this.state
    // add class
    return (
      <>
        <h4 className="font-medium"> {STEPS[activeStep].title} </h4>
        <p> {STEPS[activeStep].description} </p>
      </>
    )
  }

  renderStepImg = () => {
    return (
      // <img src={step_one} alt="Submitt App"/>
      <ProcessIllustration />
    )
  }

  prevStep = () => {
    let { activeStep } = this.state
    if (activeStep > 0) {
      this.fadeOut()
      window.setTimeout(() => {
        this.updateSlide(activeStep - 1)
        this.fadeIn()
      }, 250)
    }
  }

  nextStep = () => {
    let { activeStep } = this.state
    if (activeStep < STEPS.length - 1) {
      this.fadeOut()
      window.setTimeout(() => {
        this.updateSlide(activeStep + 1)
        this.fadeIn()
      }, 250)
    }
  }

  updateSlide = slideNr => {
    this.setState({ activeStep: slideNr })
  }

  fadeOut = () => {
    // eslint-disable-next-line no-undef
    let target = document.getElementById("stepInfo")
    target.classList.remove("active")
  }

  fadeIn = () => {
    // eslint-disable-next-line no-undef
    let target = document.getElementById("stepInfo")
    target.classList.add("active")
  }

  render() {
    let { activeStep } = this.state
    return (
      <Container name="how-it-works">
        <h1 style={{ display: "none" }}>How it works</h1>
        <StepHero>{this.renderStepImg()}</StepHero>
        <InfoBlock>
          <Controls>
            <Pagination>
              <span className="font-semi-bold">0{activeStep + 1}</span>
              <span className="line"></span>
              <span className="font-semi-bold">0{STEPS.length}</span>
            </Pagination>
            <Arrows>
              <svg
                className={activeStep > 0 ? "active prev" : "prev"}
                onClick={this.prevStep}
                x="0px"
                y="0px"
                viewBox="0 0 161 73.6"
              >
                <path
                  d="M119.8,1.8L119.8,1.8c-2.4,2.4-2.4,6.3,0,8.7l20,20h-25.2H11.4H6.2c-3.4,0-6.2,2.8-6.2,6.2S2.8,43,6.2,43h5.3
                                    h103.3h25.2l-20,20c-2.4,2.4-2.4,6.3,0,8.7l0,0c2.4,2.4,6.3,2.4,8.7,0l30.6-30.6c2.4-2.4,2.4-6.3,0-8.7L128.6,1.8
                                    C126.2-0.6,122.3-0.6,119.8,1.8z"
                />
              </svg>
              <svg
                className={activeStep < STEPS.length - 1 ? "active" : null}
                onClick={this.nextStep}
                x="0px"
                y="0px"
                viewBox="0 0 161 73.6"
              >
                <path
                  d="M119.8,1.8L119.8,1.8c-2.4,2.4-2.4,6.3,0,8.7l20,20h-25.2H11.4H6.2c-3.4,0-6.2,2.8-6.2,6.2S2.8,43,6.2,43h5.3
                                    h103.3h25.2l-20,20c-2.4,2.4-2.4,6.3,0,8.7l0,0c2.4,2.4,6.3,2.4,8.7,0l30.6-30.6c2.4-2.4,2.4-6.3,0-8.7L128.6,1.8
                                    C126.2-0.6,122.3-0.6,119.8,1.8z"
                />
              </svg>
            </Arrows>
          </Controls>
          <StepInfo className="active" id="stepInfo">
            {this.renderStepInfo()}
          </StepInfo>
          <Button to="/sign-up">Get Started</Button>
        </InfoBlock>
      </Container>
    )
  }
}

export default Process
