import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "react-scroll"

import hero from "../images/icons/hero.png"
import app_store from "../images/icons/apple-logo.svg"
import play_store from "../images/icons/google-logo.svg"

import youtube from "../images/icons/youtube.svg"

const scrolling = keyframes`
  0%, 30% { transform: translateY(0); }
  45% { transform: translateY(100px); }
  60%, 100% { transform: translateY(0); }
`

const Container = styled.section`
  min-height: 100vh;
  display: grid;
  align-items: flex-end;
  grid-template: 1fr auto / 1fr;
  background-color: var(--alabaster);
  padding: 0 var(--gutter-s);
  @media (min-width: 768px) {
    align-items: center;
    grid-template: 1fr auto / 1fr 1fr;
    padding: 0 var(--gutter-l);
    grid-gap: 0 var(--gutter-l);
  }
`

const InfoBlock = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  h2,
  p {
    color: var(--manatee);
  }
  h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 2rem;
  }
  @media (min-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    h2 {
      font-size: 3rem;
    }
  }
`

const Hero = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  padding: var(--gutter-m) 0 0;
  display: flex;
  justify-content: center;
  img {
    max-height: 40vh;
  }
  @media (min-width: 768px) {
    align-self: flex-end;
    padding: 0 0 0;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    img {
      width: auto;
      max-height: 80vh;
    }
  }
`

const Scroll = styled(Link)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: var(--manatee);
    padding: 0 0 var(--gutter-s);
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
      g {
        path {
          fill: var(--manatee);
          &.mouse {
            position: relative;
          }
          &.wheel {
            animation: ${scrolling} 5s linear infinite;
          }
        }
      }
    }
    &:hover {
      color: var(--manatee);
    }
  }
`

const AppStoreContainer = styled.div`
  display: flex;
  img {
    width: 100px;
    height: auto;
    &:first-child {
      margin-right: 2rem;
    }
  }
  @media (min-width: 768px) {
    img {
      width: 150px;
    }
  }
`

const Youtube = styled.div`
  display: inline-flex;
  padding: 1rem 1.25rem;
  background: rgb(255, 179, 161, 1);
  background: linear-gradient(
    90deg,
    rgba(255, 179, 161, 1) 35%,
    rgba(255, 141, 157, 1) 100%
  );
  border-radius: 60px;
  margin-top: 2rem;
  cursor: pointer;
  span {
    color: white;
    font-size: 0.9rem;
  }
  img {
    margin-right: 15px;
    height: 20px;
    width: auto;
  }
`

const Player = styled.div`
  position: fixed;
  z-index: 99;
  top; 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.90);
  opacity: ${props => (props.active ? `1` : `0`)};
  transition: all 300ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.active ? `all` : `none`)};
  span {
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    cursor: pointer;
  }
  .content {
    width: 90vw;
    height: 60vh;
    background-color: black;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .content {
      width: 65vw;
      height: 65vh;
    }
  }
`

const Home = () => {
  const [youtubeActive, setYoutubeActive] = useState(false)
  const toggleYoutube = () => {
    setYoutubeActive(!youtubeActive)
  }
  return (
    <Container>
      <h1 style={{ display: "none" }}>Home</h1>
      <InfoBlock>
        <h2>
          Submit your claims <br />
          and get paid, <strong>faster.</strong>
        </h2>
        <p>
          Manage your doctors, patients, assistants and medical bureaus from the
          palm of your hand and speed up the claims process with Submitt.
        </p>
        <AppStoreContainer>
          <a
            href="https://apps.apple.com/za/app/submitt/id1457848422"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={app_store} alt="Submitt App Store download" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=me.submitt"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={play_store} alt="Submitt Play Store download" />
          </a>
        </AppStoreContainer>
        <Youtube onClick={toggleYoutube}>
          <img src={youtube} alt="Submitt App | Youtube" />
          <span>Get to know the new Submitt</span>
        </Youtube>
      </InfoBlock>
      <Hero>
        <img src={hero} alt="Submitt App" />
        {/* <HomeIllustration /> */}
      </Hero>
      <Scroll to="how-it-works" spy={true} smooth={true} duration={500}>
        <svg x="0px" y="0px" viewBox="0 0 380 630">
          <g>
            <path
              className="mouse"
              d="M190,0C85.2,0,0,85.2,0,190v250c0,104.8,85.2,190,190,190c104.8,0,190-85.2,190-190V190
                                C380,85.2,294.8,0,190,0z M350,440c0,88.2-71.8,160-160,160h0c-88.2,0-160-71.8-160-160V190c0-88.2,71.8-160,160-160
                                c88.2,0,160,71.8,160,160V440z"
            />
            <path
              className="wheel"
              d="M190,164.7c-11,0-20,9-20,20v80c0,11,9,20,20,20c11,0,20-9,20-20v-80C210,173.7,201,164.7,190,164.7z"
            />
          </g>
        </svg>
      </Scroll>
      <Player active={youtubeActive}>
        <div className="content">
          <iframe
            src="https://www.youtube.com/embed/giyehxYzxtc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <span onClick={toggleYoutube}>close</span>
      </Player>
    </Container>
  )
}

export default Home
