import React from 'react';
import styled, { keyframes } from 'styled-components'

import SubmittLogo from './submitt-logo.png'

const pillWiggle = keyframes`
  50% { 
    transform: translatey(0.5px) rotate(0.3deg);
  }
`

const pillWiggle2 = keyframes`
  50% { 
    transform: translatey(0.3px) rotate(0.5deg);
  }
`
/*
const circle = keyframes`
  0% {opacity: 1}
  50% {opacity: 0.8}
  100% {opacity: 1}
`
*/
const Container = styled.div`
    width: 100%;
    height: 100%;
    svg {
        max-width : 50vw; 
        max-height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        path {
            &.st0 {
                opacity:0.4;
                fill:#A8C4E5;
                enable-background:new;
            }
            &.st1 {
                fill:#3F4C91;
            }
            &.st2 {
                fill:#E1E8EF;
            }
            &.st3 {
                fill:#F0F6FC;
            }
            &.st4 {
                fill:#F7F7FB;
            }
            &.st5 {
                fill:#FFFFFF;
            }
            &.plant {
                fill:#467DE4;
            }
            &.st6 {
                fill:#45446C;
            }
            &.st7 {
                fill:#A8C4E5;
            }
            &.st8 {
                fill:#A9C5E3;
            }
            &.st9 {
                fill:#343658;
            }
            &.st10 {
                opacity:0.17;
                fill:#A8C4E5;
                enable-background:new;
            }
            &.st11 {
                fill:#E9F5FE;
            }
            &.st12 {
                opacity:0.65;
                fill:#89B2DB;
                enable-background:new
            }
            &.st13 {
                fill:#1E345B;
            }
            &.st14 {
                fill:#E6E6ED;
            }
            &.st15 {
                fill:#E9E8E6;
            }
            &.st16 {
                fill:#D3DDE5;
            }
            &.st17 {
                fill:#353559;
            }
            &.st18 {
                fill:#D4E1F1;
            }
            &.st19{
                fill:#CBCBD8;
            }
            &.st20{
                opacity:0.61;
                fill:#F3F3F3;
                enable-background:new;
            }
        }
        ellipse {
            &.st0{
                opacity:0.4;
                fill:#A8C4E5;
                enable-background:new;
            }
            &.st5 {
                fill:#FFFFFF;
            }
            &.st17 {
                fill:#353559;
            }
        }
        polygon {
            &.st2 {
                fill:#E1E8EF;
            }
        }
    }
`

const Pill = styled.g`
  animation: ${pillWiggle} 1.5s ease-in-out infinite;
`

const Pill2 = styled.g`
  animation: ${pillWiggle2} 2s ease-in-out infinite;
`

const PricingIllustration = () => {
    return (
        <Container>
             <svg x="0px" y="0px" viewBox="0 0 607.2 355.3">
                <g>
                    <path className="st0" d="M501.2,298.9L303.3,184.7c-2.3-1.3-2-3.7,0.6-5.2l94.8-54.7c2.6-1.5,6.7-1.7,9-0.4l197.9,114.2
                        c2.3,1.3,2,3.7-0.6,5.2l-94.8,54.7C507.5,300.1,503.5,300.3,501.2,298.9z"/>
                    <path className="st0" d="M254.8,170.1l-89.1-54.3L22,191.2l0.3,0.2l-5.5,2.9l97.1,59.7c1.8,1.1,4,1.1,5.8,0.1l129.7-70.8
                        c0.5-0.3,0.9-0.6,1.3-1l3.9-2.1C258.5,178.1,258.6,172.5,254.8,170.1z"/>
                    <path className="st1" d="M108.1,250.9L11,191.2l143.8-75.3l89.1,54.3c3.8,2.3,3.7,8-0.2,10.1L113.9,251
                        C112.1,252,109.9,251.9,108.1,250.9z"/>
                    <path className="st2" d="M575.7,136.5L575.7,136.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
                        v-0.1l0,0c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1l0,0v-0.1
                        c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2l0,0l0,0c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2v-0.1l0,0c0,0,0,0,0-0.1
                        s-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1
                        c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1l0,0c-0.1-0.1-0.2-0.2-0.2-0.4l0,0l0,0l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
                        c0,0,0,0-0.1-0.1l0,0c0,0,0,0-0.1-0.1s-0.1-0.1-0.2-0.2l-0.1-0.1l0,0l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l0,0
                        l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l0,0l0,0l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0l0,0l0,0l-0.1-0.1
                        c-0.1,0-0.1-0.1-0.2-0.1l-1.1-0.6l-5.9,3.2c0,0,1.4,0.8,1.5,0.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
                        c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3l0,0l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0
                        c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0,0.1c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.2
                        c0,0.1,0.1,0.1,0.1,0.2l0.1,0.1c0,0.1,0.1,0.2,0.1,0.2s0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.1,0.1,0.1,0.2,0.2,0.4l0,0
                        c0,0.1,0.1,0.2,0.1,0.4l0,0c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0,0,0.1s0.1,0.2,0.1,0.4c0,0,0,0,0,0.1s0.1,0.2,0.1,0.4c0,0,0,0,0,0.1
                        s0,0.2,0.1,0.4l0,0c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
                        c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.3l-20,124.7c-0.1,0.6-2.4,4.4-1.9,4.1l5.5-4.7c1.2-1,2-2.4,2.2-3.9l19.9-122.7
                        c0.1-0.6,0.2-1.5,0.2-1.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0c0,0,0,0,0-0.1s0-0.1,0-0.2
                        C575.7,136.6,575.7,136.6,575.7,136.5L575.7,136.5z"/>
                    <g>
                        <g>
                            <path className="st2" d="M566.1,132.8c2.6,1.5,4.4,5,4,7.8l-20.5,126.9c-0.5,2.8-3,3.8-5.6,2.3L349.6,157.6c-2.6-1.5-4.4-5-4-7.8
                                l20.5-126.9c0.5-2.8,3-3.8,5.6-2.3L566.1,132.8z"/>
                        </g>
                    </g>
                    <path className="st3" d="M572.8,114.1L380.2,2.9l0,0l0,0l-0.3-0.2l0,0c-0.2-0.1-0.5-0.3-0.5-0.3c-0.1,0-0.1-0.1-0.2-0.1
                        s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1S378.7,2,378.6,2s-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1l0,0l0,0l0,0c-0.1,0-0.1,0-0.2,0h-0.1h-0.1
                        c0,0,0,0-0.1,0s-0.2,0-0.2,0l0,0l0,0c-0.1,0-0.2,0-0.3,0l0,0h-0.1H377l0,0c-0.1,0-0.1,0-0.2,0h-0.1l0,0c-0.1,0-0.3,0.1-0.4,0.1l0,0
                        l0,0C376.1,2,376,2,375.8,2.1l-5.7,3.3c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.3,0h0.1
                        c0.1,0,0.3,0,0.4,0h0.1c0.1,0,0.3,0,0.4,0l0,0c0.1,0,0.3,0.1,0.4,0.1l0,0c0.1,0,0.3,0.1,0.4,0.1l0,0c0.2,0.1,0.3,0.1,0.5,0.2
                        c0.1,0,0.1,0.1,0.2,0.1s0.1,0.1,0.2,0.1l1.1,0.6l0,0L568,117.6c0.2,0.1,0.5,0.1,0.7,0l4.2-2.5C573.3,115.1,573.3,114.4,572.8,114.1z
                        M378.3,3.2L378.3,3.2L378.3,3.2L378.3,3.2z"/>
                    <path className="st2" d="M578,121.7L578,121.7c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2v-0.1l0,0
                        c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2l0,0l0,0c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1l0,0v-0.1
                        c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2l0,0l0,0c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2v-0.1l0,0c0,0,0,0,0-0.1
                        s-0.1-0.1-0.1-0.2s-0.1-0.1-0.1-0.2l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2s-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1
                        c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1l0,0c-0.1-0.1-0.2-0.2-0.2-0.4l0,0l0,0l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
                        c0,0,0,0-0.1-0.1l0,0c0,0,0,0-0.1-0.1s-0.1-0.1-0.2-0.2l-0.1-0.1l0,0l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l0,0
                        l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l0,0l0,0l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0l0,0l0,0l-0.1-0.1
                        c-0.1,0-0.1-0.1-0.2-0.1l-4.7-2.7l-5.8,3.5l5,2.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
                        c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3l0,0l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0
                        c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2s0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.2
                        c0,0.1,0.1,0.1,0.1,0.2l0.1,0.1c0,0.1,0.1,0.2,0.1,0.2s0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.1,0.1,0.1,0.2,0.2,0.4l0,0
                        c0,0.1,0.1,0.2,0.1,0.4l0,0c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0,0,0.1s0.1,0.2,0.1,0.4c0,0,0,0,0,0.1s0.1,0.2,0.1,0.4c0,0,0,0,0,0.1
                        s0,0.2,0.1,0.4l0,0c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2s0,0.2,0,0.2c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
                        c0,0,0,0.1-0.1,0.3l-20,124.9c-0.1,0.6-2.4,4.4-1.9,4.1l5.5-4.7c1.2-1,1.9-2.4,2.2-3.9L577.7,124c0.1-0.6,0.2-1.5,0.2-1.5
                        c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l0,0l0,0c0,0,0,0,0-0.1s0-0.1,0-0.2C578,121.8,578,121.8,578,121.7L578,121.7z"/>
                    <g>
                        <g>
                            <g>
                                <path className="st1" d="M568.2,118c2.6,1.5,4.4,5,4,7.8l-20.5,126.9c-0.5,2.8-3,3.8-5.6,2.3L351.7,142.8c-2.6-1.5-4.4-5-4-7.8
                                    L368.3,8.1c0.5-2.8,3-3.8,5.6-2.3L568.2,118z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st4" d="M561.3,126.2c2.5,1.4,4.2,4.5,3.8,6.8l-18.6,106.5c-0.4,2.3-2.8,3.1-5.3,1.7L356.9,136.7
                                c-2.5-1.4-4.2-4.5-3.8-6.8l18.6-106.5c0.4-2.3,2.8-3.1,5.3-1.7L561.3,126.2z"/>
                        </g>
                    </g>
                    <path className="st5" d="M473,70.3c0,1.2-0.9,1.7-1.9,1.1c-1.1-0.6-1.9-2.1-1.9-3.3c0-1.2,0.9-1.7,1.9-1.1S473,69.1,473,70.3z"/>
                    <path className="st6" d="M546.3,277.2v-5.9l-7.6,2.9l-1.6-3.8L346.9,160.7c-2.3-1.3-6.4-1.2-9,0.4L256,208.3V207l-15,6.1v6l0,0
                        c0.2,1,0.7,1.3,1.4,1.7l197.9,114.3c2.3,1.3,6.3,1.2,9-0.3l94.8-54.7C545.5,279.2,546.3,278.2,546.3,277.2L546.3,277.2z"/>
                    <path className="st5" d="M440.2,328.9L242.3,214.7c-2.3-1.3-2-3.7,0.6-5.2l94.8-54.7c2.6-1.5,6.7-1.7,9-0.4l197.9,114.2
                        c2.3,1.3,2,3.7-0.6,5.2l-94.8,54.7C446.5,330.1,442.5,330.3,440.2,328.9z"/>
                    <path className="st7" d="M366.5,273.8l-47.9-27.6c-0.6-0.3-0.5-0.9,0.2-1.3l22.9-13.2c0.6-0.4,1.6-0.4,2.2-0.1l47.9,27.6
                        c0.6,0.3,0.5,0.9-0.2,1.3l-22.9,13.2C368,274,367,274.1,366.5,273.8z"/>
                    <g>
                        <path className="st8" d="M349.6,175.6c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L349.6,175.6z"/>
                        <path className="st8" d="M363.6,196.3c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.6,0.6,0.3,0.8L363.6,196.3z"/>
                        <path className="st8" d="M356.5,204.9c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L356.5,204.9z"/>
                        <path className="st8" d="M337.5,206.5c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.1
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L337.5,206.5z"/>
                        <path className="st8" d="M304.4,201.7c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l9.3-5.4c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L304.4,201.7z"/>
                        <path className="st8" d="M325.1,213.7c-0.3,0.2-1,0-1.6-0.3l-15.7-9.1c-0.6-0.3-0.8-0.7-0.5-0.9l9.3-5.4c0.3-0.2,1,0,1.5,0.3l15.7,9.1
                            c0.6,0.3,0.8,0.7,0.5,0.9L325.1,213.7z"/>
                        <path className="st8" d="M428.5,273.4c-0.2,0.1-0.8,0-1.2-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.4-0.7l3.5-2c0.2-0.1,0.8,0,1.2,0.2l12,6.9
                            c0.4,0.2,0.6,0.6,0.4,0.7L428.5,273.4z"/>
                        <path className="st8" d="M444.6,282.9c-0.2,0.1-0.8,0-1.2-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.4-0.7l3.5-2c0.2-0.1,0.8,0,1.2,0.2l12,6.9
                            c0.4,0.2,0.6,0.6,0.4,0.7L444.6,282.9z"/>
                        <path className="st8" d="M461.6,292.5c-0.2,0.1-0.8,0-1.2-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.4-0.7l3.3-1.9c0.2-0.1,0.8,0,1.2,0.2l12,6.9
                            c0.4,0.2,0.6,0.6,0.4,0.7L461.6,292.5z"/>
                        <path className="st8" d="M451.1,279.3c-0.2,0.1-0.8,0-1.2-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.4-0.7l3.3-1.9c0.2-0.1,0.8,0,1.2,0.2l12,6.9
                            c0.4,0.2,0.6,0.6,0.4,0.7L451.1,279.3z"/>
                        <path className="st8" d="M407.8,261.3c-0.3,0.2-0.9,0.1-1.3-0.2L328.3,216c-0.4-0.2-0.6-0.6-0.3-0.8l9.3-5.4c0.3-0.2,0.9-0.1,1.3,0.2
                            l78.2,45.1c0.4,0.3,0.6,0.6,0.3,0.8L407.8,261.3z"/>
                        <path className="st8" d="M399.7,254.5c0,0-0.3-0.1-0.7-0.3l-62.2-35.9c-0.3-0.2-0.6-0.4-0.6-0.4l0.6-0.3c0,0,0.3,0.1,0.7,0.3l62.2,35.9
                            c0.3,0.2,0.6,0.4,0.6,0.4L399.7,254.5z"/>
                        <path className="st8" d="M323,210.7h-0.1l-9.2-5.3l-0.1-0.1l0.6-0.3h0.1l9.2,5.3c0.1,0,0.1,0.1,0.1,0.1L323,210.7z"/>
                        <path className="st8" d="M299.4,196.1L299.4,196.1l-4-2.3l0,0l0.6-0.3h0.1l3.9,2.2l0,0L299.4,196.1z"/>
                        <path className="st8" d="M353.9,216c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.3,0.6,0.6,0.3,0.8L353.9,216z"/>
                        <path className="st8" d="M370.5,225.6c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L370.5,225.6z"/>
                        <path className="st8" d="M387,235.1c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.1l12,6.9
                            c0.4,0.2,0.5,0.6,0.3,0.8L387,235.1z"/>
                        <path className="st8" d="M403.6,244.7c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L403.6,244.7z"/>
                        <path className="st8" d="M420.1,254.2c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.1
                            l12,6.9c0.4,0.3,0.6,0.6,0.3,0.8L420.1,254.2z"/>
                        <path className="st8" d="M436.6,263.8c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L436.6,263.8z"/>
                        <path className="st8" d="M473.9,285.2c-0.3,0.2-0.9,0.1-1.3-0.2l-32.7-18.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l32.7,18.9c0.4,0.2,0.5,0.6,0.3,0.8L473.9,285.2z"/>
                        <path className="st8" d="M373,214.4c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.3,0.5,0.6,0.3,0.7L373,214.4z"/>
                        <path className="st8" d="M389.5,223.9c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.7l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.7L389.5,223.9z"/>
                        <path className="st8" d="M406.1,233.5c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L406.1,233.5z"/>
                        <path className="st8" d="M422.6,243c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.2,0.5,0.6,0.3,0.8L422.6,243z"/>
                        <path className="st8" d="M439.2,252.5c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L439.2,252.5z"/>
                        <path className="st8" d="M455.7,262.1c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.7L455.7,262.1z"/>
                        <path className="st8" d="M472.2,271.6c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.7l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.7L472.2,271.6z"/>
                        <path className="st8" d="M380,205.8c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.3,0.6,0.6,0.3,0.8L380,205.8z"/>
                        <path className="st8" d="M396.6,215.3c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.7L396.6,215.3z"/>
                        <path className="st8" d="M413.1,224.9c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.6,0.6,0.3,0.8L413.1,224.9z"/>
                        <path className="st8" d="M429.6,234.4c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.5,0.6,0.3,0.7L429.6,234.4z"/>
                        <path className="st8" d="M446.2,244c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.3-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.2,0.5,0.6,0.3,0.7L446.2,244z"/>
                        <path className="st8" d="M462.7,253.5c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.6,0.6,0.3,0.8L462.7,253.5z"/>
                        <path className="st8" d="M479.3,263c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2l12,6.9
                            c0.4,0.2,0.5,0.6,0.3,0.7L479.3,263z"/>
                        <path className="st8" d="M347,186.7c-0.3,0.2-0.9,0.1-1.3-0.2l-20.4-11.8c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l20.4,11.8c0.4,0.3,0.6,0.6,0.3,0.8L347,186.7z"/>
                        <path className="st8" d="M506.8,266.3c-0.3,0.2-0.9,0.1-1.3-0.2l-20.4-11.8c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l20.4,11.8c0.4,0.2,0.6,0.6,0.3,0.8L506.8,266.3z"/>
                        <path className="st8" d="M340,195.3c-0.3,0.2-0.9,0.1-1.3-0.2l-24.3-14c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l24.3,14c0.4,0.3,0.6,0.6,0.3,0.8L340,195.3z"/>
                        <path className="st8" d="M320.9,197c-0.3,0.2-0.9,0.1-1.3-0.2l-16.2-9.3c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l16.2,9.3c0.4,0.2,0.5,0.6,0.3,0.8L320.9,197z"/>
                        <path className="st8" d="M366.2,185.1c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.6,0.6,0.3,0.8L366.2,185.1z"/>
                        <path className="st8" d="M382.6,194.6c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.2-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L382.6,194.6z"/>
                        <path className="st8" d="M399.2,204.2c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.5,0.6,0.3,0.8L399.2,204.2z"/>
                        <path className="st8" d="M415.7,213.7c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L415.7,213.7z"/>
                        <path className="st8" d="M432.3,223.3c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.3,0.5,0.6,0.3,0.8L432.3,223.3z"/>
                        <path className="st8" d="M448.8,232.8c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.1
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.8L448.8,232.8z"/>
                        <path className="st8" d="M465.3,242.3c-0.3,0.2-0.9,0.1-1.3-0.2l-12-6.9c-0.4-0.3-0.6-0.6-0.3-0.8l8-4.6c0.3-0.2,0.9-0.1,1.3,0.2
                            l12,6.9c0.4,0.2,0.6,0.6,0.3,0.8L465.3,242.3z"/>
                        <path className="st8" d="M481.9,251.9c-0.3,0.2-0.9,0.1-1.3-0.1l-12-6.9c-0.4-0.2-0.5-0.6-0.3-0.7l8-4.6c0.3-0.2,0.9-0.1,1.3,0.1
                            l12,6.9c0.4,0.2,0.5,0.6,0.3,0.7L481.9,251.9z"/>
                        <path className="st8" d="M491.5,260.3c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0-0.6,0.1l-8,4.6c-0.1,0.1-0.2,0.2-0.1,0.3
                            c0.1,0.1,0.2,0.3,0.4,0.4l3.1,1.8l-10.4,6c-0.1,0.1-0.2,0.2-0.1,0.3c0.1,0.1,0.2,0.3,0.4,0.4l8.1,4.7c0.2,0.1,0.5,0.2,0.7,0.2
                            c0.3,0,0.5,0,0.6-0.1l18.9-10.9c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.3-0.4-0.4L491.5,260.3z"/>
                        <path className="st8" d="M318.8,193.1h-0.1l-9.2-5.3c-0.1,0-0.1-0.1-0.1-0.1l0.6-0.3h0.1l9.2,5.3c0.1,0,0.1,0.1,0.1,0.1L318.8,193.1z"
                            />
                    </g>
                    <path className="st9" d="M18.1,187.9l-7.1,3.3c-3.2-7.4-1-18.1-1-18.1c0.9-3.4,4.4-5.5,7.9-4.6l3.4,0.9c3.4,0.9,5.5,4.4,4.6,7.9
                        l-1.3,5.1C23.6,185.8,21.5,188.8,18.1,187.9z"/>
                    <path className="st5" d="M106.1,242.9L19,189.7c-2.8-1.7-2.7-5.9,0.2-7.5l134.9-73.7l85.3,52c5.5,3.4,5.3,11.4-0.3,14.5l-124.5,68
                        C111.9,244.5,108.7,244.5,106.1,242.9z"/>
                    <path className="st5" d="M106.1,242.9l-84.7-51.6c-4-2.4-3.8-8.2,0.2-10.4l132.3-72.2l85.3,52c5.5,3.4,5.3,11.4-0.3,14.5l-124.5,68
                        C111.9,244.5,108.7,244.5,106.1,242.9z"/>
                    <path className="st9" d="M13.9,175.2l-1.2-0.7c-2.6-1.6-2.2-4.3,0.9-5.9l130.3-71.1c3.1-1.7,7.7-1.8,10.3-0.2l1.2,0.7
                        c2.6,1.6,2.2,4.3-0.9,5.9L24.3,175C21.2,176.7,16.5,176.8,13.9,175.2z"/>
                    <path className="st1" d="M109.5,233.4l-94.8-57.8l141.5-77.2l89.1,54.3c3.8,2.3,3.7,8-0.2,10.1l-129.7,70.8
                        C113.5,234.6,111.3,234.5,109.5,233.4z"/>
                    <path className="st10" d="M187.4,152.4c-0.6-0.1-1.3-0.3-1.9-0.4c3.4-8.8-9-15.9-13.3-18c-0.9-0.5-2-0.6-3-0.3c-4,1-15.1,4.2-25.8,11.5
                        c-6.1,2.7-14.6,7.1-22.3,12.7c-0.3,0.3-0.7,0.5-1,0.8c-1.4,0.5-2.7,1.1-4.1,1.6c-9.8,4.1-16.8,8.8-21.2,12.1
                        c-2.7,1.9-4.7,3.6-6.2,5.1c-2,1.9-1.7,5.1,0.5,6.7c2.8,2.1,7.2,4.7,12.6,6.4c0.4,3.6,1.6,7.2,3.5,10.8c1.5,2.6,4.8,3.4,7.5,3.1
                        c1.6-0.2,3.1-0.9,3.8-1.8c0.6-0.7,0.8-1.6,0.4-2.7c0-0.1-1.3-3.3-2.1-7.8c4.2-0.3,8.7-1.5,13.4-3.9c23.5-12,5.6-25.7,5.6-25.7
                        l0.5-0.3l1.7-1l0.5-0.3c0,0,18.6,14.8,42-1.3c0.3-0.2,0.5-0.4,0.8-0.5c3.2,0.4,5.2,0.7,5.3,0.8c3.7,0.6,6.4-0.4,7.4-1.8
                        c0.1-0.1,0.2-0.2,0.2-0.4C193,156.4,191.5,153.4,187.4,152.4z M180.2,150.4c0.1-0.5,0.1-1,0.1-1.5
                        C180.4,149.3,180.3,149.8,180.2,150.4z"/>
                    <path className="st11" d="M95,200.5c-4.6-8.4-4.9-16.9-1.1-25.1c3.1-6.6,8.9-13,17.2-19.1c14.1-10.4,30.8-16.3,31-16.4l1.2,1.1
                        c-0.2,0.1-18.8,10.7-34.3,27.9c-9.6,10.6-2.2,30-2.1,30.2c0.4,1.1,0.2,2-0.4,2.7c-0.8,1-2.3,1.7-3.9,1.9
                        C99.9,204,96.6,203.2,95,200.5z"/>
                    <path className="st11" d="M105.9,158.7c10.5-4.4,21.4-7.4,32.3-8.8c13.7-1.8,27-2.2,40.4,0.8c4.2,1,5.7,4,4.9,5.5
                        c-0.1,0.1-0.1,0.2-0.2,0.4c-1,1.4-3.8,2.4-7.6,1.8c-0.3-0.1-31.3-5.1-49.2-0.2c-29.1,8-47.6,18-47.8,18.1l0.3-0.6
                        C79.1,175.6,88.1,166.2,105.9,158.7z"/>
                    <path className="st12" d="M82.3,178.5c0,0,2.8,10.2,14.1,10.2c11.2,0,26.2-2.8,28.9-10.2c2.7-7.3,0.7-17.3-5.1-19.4
                        C114.3,157.1,87.9,166,82.3,178.5z"/>
                    <path className="st12" d="M127.4,153.2c0,0,2.8,10.2,14.1,10.2c11.2,0,26.2-2.8,28.9-10.2c2.7-7.3,0.7-17.3-5.1-19.4
                        C159.4,131.8,133,140.7,127.4,153.2z"/>
                    <path className="st11" d="M78.1,176.2c-2,1.9-1.7,5.2,0.5,6.8c6.8,4.9,22.1,13.1,39.8,4.1c23.9-12.2,5.7-26.1,5.7-26.1l0.6-0.3l1.7-1
                        l0.6-0.3c0,0,18.9,15.1,42.7-1.3c18.2-12.5-0.9-23.4-6.5-26.2c-0.9-0.5-2-0.6-3-0.3c-6,1.5-27.4,7.7-40.5,25.1l-0.1,0.1
                        C95.1,162.4,83.2,171.2,78.1,176.2z M134,157.3L134,157.3c-2.7-1.4-3.2-5.1-1-7.2c9.4-9,21.5-12.8,25.9-13.9
                        c1.1-0.3,2.3-0.1,3.3,0.4c4.6,2.5,16.5,10.6,3.1,18.8C149.1,165.5,134,157.3,134,157.3z M87.4,182.9c-2.9-1.4-3.4-5.4-1-7.5
                        c4.4-3.7,12.7-9.1,27.3-12.8c1.2-0.3,2.5-0.1,3.6,0.6l2.9,1.9c0,0,13.7,8.9-4.1,18C104.5,189.1,93.5,185.8,87.4,182.9z"/>
                    <path className="st5" d="M94.2,183.2L94.2,183.2c-0.7,0-1.4-0.6-1.4-1.4v-8.1c0-0.7,0.6-1.4,1.4-1.4l0,0c0.7,0,1.4,0.6,1.4,1.4v8.1
                        C95.6,182.5,95,183.2,94.2,183.2z"/>
                    <path className="st5" d="M99,181.7L99,181.7c-0.5,0-0.9-0.4-0.9-0.9v-9c0-0.5,0.4-0.9,0.9-0.9l0,0c0.5,0,0.9,0.4,0.9,0.9v9
                        C99.9,181.2,99.5,181.7,99,181.7z"/>
                    <path className="st5" d="M141.1,156.8L141.1,156.8c-0.7,0-1.4-0.6-1.4-1.4v-8.1c0-0.7,0.6-1.4,1.4-1.4l0,0c0.7,0,1.4,0.6,1.4,1.4v8.1
                        C142.5,156.1,141.9,156.8,141.1,156.8z"/>
                    <path className="st5" d="M145.9,155.3L145.9,155.3c-0.5,0-0.9-0.4-0.9-0.9v-9c0-0.5,0.4-0.9,0.9-0.9l0,0c0.5,0,0.9,0.4,0.9,0.9v9
                        C146.8,154.8,146.4,155.3,145.9,155.3z"/>
                    <g>
                        <path className="st0" d="M73.2,282.4c-7.6,5-19.9,5.4-28,1.1c-0.2,0.1-0.5,0.3-0.7,0.4c1,1.5,2.6,2.9,4.6,4.1c8.1,4.8,21,4.5,28.9-0.6
                            c7.6-5,7.7-12.8,0.3-17.6v0.1C80.1,274.1,78.5,278.9,73.2,282.4z"/>
                        <path className="st0" d="M130,311.8c-8.2,6.4-16.7,12.4-26.8,15.5c-4.4,1.3-9,2.1-13.5,2c-3.8-0.1-7.7-0.6-11.5-0.4
                            c-7,0.3-13.1,4.2-18,8.8c-5.6,5.3-10.8,12-18.7,13.7s-15.7-1.5-22.4-5.8c0.2,0.2,0.4,0.3,0.7,0.5c6.9,5.2,15.8,10.1,24.8,9
                            c8.2-1.1,13.7-7.3,19.2-12.8c5-5,11.3-9.6,18.5-10c3.7-0.2,7.4,0.2,11.1,0.3c4.7,0.2,9.5-0.4,14-1.8c10.2-3,18.8-9,27.1-15.5
                            c2.1-1.6,4.2-3.3,6.3-4.9l-5.4-3C133.7,309,131.9,310.4,130,311.8z"/>
                        <path className="st0" d="M44.3,283c-1.5-0.9-2.8-1.9-3.7-3c-4.1,2.4-8.2,4.9-12.3,7.4c-8,5-15.8,11-19.7,19.9
                            c-3.1,7.3-2.7,15.1,1.7,21.7c2.9,4.4,7.3,8.2,12,11.1c-2.8-2.2-5.2-4.8-7.1-7.6c-4.6-6.9-4.9-15-1.5-22.5
                            c4-8.6,11.6-14.5,19.5-19.3c3.7-2.3,7.4-4.5,11.2-6.8c0.2-0.1,0.5-0.3,0.7-0.4C44.9,283.4,44.6,283.2,44.3,283z"/>
                        <path className="st0" d="M214.1,248.8l-10.4-5.3c-0.5-0.2-0.9-0.6-1.2-1c-5.5-0.7-11.2-0.3-16.4,1.1c-2.4,1.1-4.8,2.4-7.5,3.9l-9.7,5.9
                            l0,0l-31.3,19.1c-9.1,5.2-13,8.5-9,12.7c1.5-2.1,4.9-4.3,9.6-7l31.3-19.1l0,0l9.7-5.9c8.4-4.8,14.6-7.8,23.3-5.8
                            c0.4,0.7,1,1.4,1.8,1.8l10.4,5.3c2.1,1.1,4.8,0.2,5.9-1.9c0.9-1.8,0.5-3.9-0.9-5.2C218.4,249.2,216,249.8,214.1,248.8z"/>
                        <path className="st0" d="M232.6,294c0.4-0.2,0.6-0.2,0.6-0.2l14.2-8.5c6.6-3.9,8.8-9.3,6.6-14.2c-0.7,3.1-3.1,6.1-7.2,8.5l-14.2,8.5
                            c0,0-0.2,0.1-0.6,0.2c-0.1,0.1-0.3,0.3-0.4,0.4l-39.1,23.2c-9.7,5.7-22,7.4-36.4-2.6c-3,0.9-6.8,0.6-9.4-0.8l-7-3.9
                            c-1.4,1-2.8,2-4.2,3l5.4,3l6.5,3.6c2.6,1.4,6.4,1.7,9.4,0.8c14.5,10,26.7,8.3,36.4,2.6l39.1-23.2
                            C232.4,294.2,232.5,294.1,232.6,294z"/>
                        <path className="st0" d="M128.3,298.3c-2.5-1.4-3.1-3.5-1.8-5.2c-4.1-2.2-6.6-4.4-7.8-6.6c-1.3,3.9,0.7,8.2,8.4,12.4
                            c-1.3,1.8-0.7,3.9,1.8,5.2l0.2,0.1c1.4-1.1,2.7-2.1,4.1-3.1L128.3,298.3z"/>
                        <path className="st0" d="M242.3,263c-1.1,0.4-2.3,0.3-3.4-0.2l-10.4-5.3c-0.4-0.2-0.7-0.4-1-0.7c-0.1,0.2-0.2,0.3-0.3,0.5
                            c-1.1,2.1-0.2,4.8,1.9,5.9l10.4,5.3c1.1,0.6,2.3,0.6,3.4,0.2c1.1,0.9,2,1.7,2.6,2.5C246.9,268.8,246.2,266.2,242.3,263z"/>
                        <path className="st5" d="M232,288.3c0.4-0.2,0.6-0.2,0.6-0.2l14.2-8.5c4.1-2.4,6.5-5.4,7.2-8.5c1-4.5-1.5-9.2-7.6-12.7l-1.5-0.8
                            c0.4,1.1,0.3,2.3-0.2,3.4c-0.5,1-1.4,1.8-2.4,2.1c4,3.2,4.7,5.7,3.2,8.3c-1.5,2.5-5.1,5-9.7,8l-7.6,4.6l0,0
                            C230.4,282.5,233.5,286.5,232,288.3z"/>
                        <path className="st13" d="M161.1,306.6l-2.8,1.7c-0.6,0.4-1.4,0.7-2.2,0.9c14.5,10,26.7,8.3,36.4,2.6l39.1-23.2
                            c0.2-0.1,0.3-0.2,0.4-0.4c1.5-1.7-1.7-5.8-3.9-4.5l0,0l-34.3,19.9c-11.8,7.5-17.9,9.2-31,1.3C162.4,305.6,161.8,306.1,161.1,306.6z
                            "/>
                        <path className="st13" d="M131.5,295.4c-1.9-0.8-3.6-1.6-5-2.4c-1.3,1.8-0.7,3.9,1.8,5.2l4.9,2.7c2.9-2.1,5.9-4.1,9.1-5.7
                            c3.7-1.9,7,3.7,3.3,5.6c-2.1,1.1-4.1,2.3-6,3.6l7,3.9c2.6,1.4,6.4,1.7,9.4,0.8c0.8-0.2,1.5-0.5,2.2-0.9l2.8-1.7
                            c0.8-0.5,1.3-1,1.7-1.6c1.2-1.7,0.5-3.8-1.9-5.1l-18.3-10.2c-2.5-1.4-6-1.6-9-0.9c1.7,1,3.8,2,6.2,3.2L131.5,295.4z"/>
                        <path className="st5" d="M168.8,253.5l9.7-5.9c2.7-1.5,5.1-2.9,7.5-3.9c5.1-2.3,9.8-3.3,15.8-1.9c-0.7-1.2-0.8-2.8-0.1-4.1
                            c0.1-0.3,0.3-0.5,0.5-0.8c-8.7-1.1-18.2,0.3-24.7,4.3l-12.7,7.7C167.4,247.3,170.2,252.6,168.8,253.5z"/>
                        <path className="st13" d="M126.5,293c1.5,0.8,3.1,1.6,5,2.4l8.2-3.4c-2.4-1.1-4.5-2.2-6.2-3.2c-2.3-1.3-3.9-2.5-5-3.6
                            c-4-4.2-0.1-7.5,9-12.7l31.3-19.1l0,0c1.4-0.9-1.4-6.2-4-4.5l0,0L126.3,272c-6.3,3.9-10.6,9-7.6,14.3
                            C119.9,288.6,122.4,290.8,126.5,293z"/>
                        <path className="st13" d="M201.8,241.8c0.2,0.3,0.4,0.6,0.6,0.8c0.3,0.4,0.8,0.7,1.2,1l10.4,5.3c1.9,1,4.3,0.4,5.5-1.4
                            c0.1-0.2,0.2-0.3,0.3-0.5l0,0c1.1-2.1,0.2-4.8-1.9-5.9l-10.4-5.3c-1.9-0.9-4.1-0.4-5.3,1.1c-0.2,0.2-0.4,0.5-0.5,0.8
                            C201.1,239,201.1,240.5,201.8,241.8z"/>
                        <path className="st13" d="M238.8,262.8c1.1,0.6,2.3,0.6,3.4,0.2c1-0.4,1.9-1.1,2.4-2.1c0.6-1.1,0.6-2.3,0.2-3.4c-0.4-1-1.1-1.9-2.1-2.5
                            l-10.4-5.3c-2.1-1.1-4.8-0.2-5.9,1.9c-0.9,1.8-0.5,3.9,0.9,5.2c0.3,0.3,0.6,0.5,1,0.7L238.8,262.8z"/>
                        <path className="st14" d="M44.3,283c0.3,0.2,0.6,0.3,0.9,0.5c8.1,4.3,20.4,3.9,28-1.1c5.3-3.5,6.9-8.3,5-12.5c-0.5,1.3-1.4,2.6-2.6,3.8
                            v1.3H74c-0.3,0.2-0.7,0.5-1,0.7c-7.4,4.9-19.4,5.4-27.5,1.4c-1.7,1-3.3,1.9-5,2.9C41.5,281.1,42.8,282.1,44.3,283z"/>
                        <path className="st15" d="M75.6,273.7c-0.5,0.5-1,0.9-1.5,1.3h1.6L75.6,273.7z"/>
                        <path className="st5" d="M49,267.5c-0.6-1.9,0.3-3.9,2.6-5.4c3.7-2.4,9.9-2.6,13.7-0.3c3.8,2.3,3.9,6.1,0.2,8.6
                            c-2.9,1.9-7.3,2.4-10.9,1.5c-3,1.8-6,3.5-9,5.3c8.1,4,20.1,3.5,27.5-1.4c0.4-0.2,0.7-0.5,1-0.7c0.6-0.4,1.1-0.9,1.5-1.3
                            c1.2-1.2,2.1-2.5,2.6-3.8v-0.1c1.7-4.3-0.2-9-5.6-12.2c-8.1-4.8-21-4.5-28.9,0.6c-5.3,3.5-6.9,8.3-5,12.5c0.3,0.7,0.7,1.4,1.2,2
                            C43,271,46,269.3,49,267.5z"/>
                        <path className="st13" d="M65.4,270.3c3.7-2.4,3.6-6.3-0.2-8.6c-3.8-2.3-10-2.1-13.7,0.3c-2.3,1.5-3.1,3.5-2.6,5.4
                            c1.4-0.8,2.7-1.6,4.1-2.4c3.6-2.1,6.9,3.5,3.3,5.6c-0.6,0.4-1.2,0.7-1.8,1.1C58.1,272.7,62.5,272.3,65.4,270.3z"/>
                        <path className="st13" d="M41.4,351.5c7.9-1.7,13.2-8.4,18.7-13.7c4.9-4.7,11.1-8.6,18-8.8c3.8-0.1,7.7,0.3,11.5,0.4
                            c4.6,0.1,9.2-0.6,13.5-2c10.1-3.1,18.6-9.1,26.8-15.5c1.8-1.4,3.6-2.8,5.5-4.2c1.4-1,2.8-2.1,4.2-3c1.9-1.3,3.9-2.5,6-3.6
                            c3.7-1.9,0.4-7.6-3.3-5.6c-3.2,1.6-6.2,3.6-9.1,5.7c-1.4,1-2.8,2-4.1,3.1c-6.3,4.8-12.3,9.9-19.4,13.6c-3.9,2-7.9,3.5-12.2,4.4
                            c-4.2,0.9-8.2,0.7-12.5,0.5c-6.4-0.4-12.4-0.2-18.3,2.7c-7.1,3.4-12,9-17.6,14.3c-2.4,2.3-5.1,4.5-8.4,5.4c-4,1.1-8.2,0.2-12-1.4
                            c-2.2-0.9-4.3-2.1-6.5-3.4c-4.7-2.9-9.1-6.7-12-11.1c-4.4-6.6-4.8-14.4-1.7-21.7c3.8-8.9,11.6-14.9,19.7-19.9c4-2.5,8.1-5,12.3-7.4
                            c1.7-1,3.3-1.9,5-2.9c3-1.8,6-3.5,9-5.3c0.6-0.4,1.2-0.7,1.8-1.1c3.6-2.1,0.3-7.8-3.3-5.6c-1.4,0.8-2.7,1.6-4.1,2.4
                            c-3,1.8-6,3.5-9,5.3c-0.6,0.4-1.2,0.7-1.9,1.1c-4,2.3-7.9,4.7-11.8,7.1c-7.8,4.8-15.4,10.3-20.5,18c-4.8,7.4-7.2,16.8-4.7,25.4
                            c2.3,8,8.2,14.3,14.9,19.1c1,0.7,2.1,1.5,3.2,2.2C25.7,349.9,33.6,353.1,41.4,351.5z"/>
                    </g>
                    <ellipse transform="matrix(5.422426e-03 -1 1 5.422426e-03 158.5728 407.5321)" className="st0" cx="284.2" cy="124" rx="20.8" ry="36"/>
                    <g>
                        <path className="st2" d="M291.7,134.9c-10.2,5.9-26.9,5.9-37.2-0.1c-10.3-5.9-10.3-15.5-0.1-21.5c10.2-5.9,26.9-5.9,37.2,0.1
                            C301.8,119.3,301.9,129,291.7,134.9z"/>
                        <polygon className="st2" points="234.5,87.3 246.8,125.2 299.1,126 311,89.5 	"/>
                        <path className="st16" d="M296.2,90.1l-11.9,37l0,0c-0.7,1-3.2,5.8-7.4,8.3c-3.1,1.8-6.8,2.9-10.7,3.5c8.8,1.5,18.7,0.5,25.6-3.5
                            c4.3-2.5,6.8-7.4,7.4-8.4l0,0l11.9-37h-14.9V90.1z"/>
                        
                            <ellipse transform="matrix(5.424366e-03 -1 1 5.424366e-03 186.6438 357.3604)" className="st5" cx="273" cy="84.9" rx="22.4" ry="38.8"/>
                        
                            <ellipse transform="matrix(5.425207e-03 -1 1 5.425207e-03 187.0928 356.4272)" className="st17" cx="272.7" cy="84.2" rx="19.2" ry="33.3"/>
                        <g>
                            <path className="st18" d="M247.3,76.4L247.3,76.4c-2-2.6-3.7-5.6-3.3-9c0.2-1.5,0.8-2.9,2-4c3-2.7,7.2-1,9.7,1.6c2.2,2.2,3.7,5,6.1,7
                                c-3-9.6-6-19.1-9.1-28.7c-1.2-3.7-2.3-7.6-0.4-11.3c1.1-2,3.3-3.3,5.5-2.9c5.8,0.9,4.9,10.7,10.1,13.5c-3.7-7.1-6.3-14.8-7.6-22.8
                                c-0.9-5.8-1.1-12,3.1-16.7c2.3-2.6,5.8-3.9,9.1-2.9c5.9,1.8,7,9.2,7.2,15.2c0.3,7.5,0.5,15,0.8,22.4c1.3-5.5,3.2-11.6,7.1-15.5
                                c2-2,5.4-0.7,5.6,2.1c0.7,8.7-1,17.5-5,25.3c0.7-1.2,1.7-2.3,2.8-3.2c3-2.4,7.4-0.2,7.3,3.6c-0.2,8.5-3.7,17-9.8,23.1l3-3.3
                                c1.4-1.5,3.9-0.5,3.9,1.5c-0.1,5.7-2.6,11.4-6.9,15.2C275.4,98.3,256.2,88.2,247.3,76.4z"/>
                        </g>
                    </g>
                    <path className="st0" d="M282,294l15.4-0.2c2.7,0,4.9,2.2,4.9,4.8v2.1c0,2.7-2.2,4.9-4.8,4.9l-15.4,0.2c-2.7,0-4.9-2.2-4.9-4.8v-2.1
                        C277.2,296.3,279.3,294,282,294z"/>
                    <path className="st14" d="M280,293.2l15.4-0.2c2.7,0,4.9,2.2,4.9,4.8v2.1c0,2.7-2.2,4.9-4.8,4.9l-15.4,0.2c-2.7,0-4.9-2.2-4.9-4.8v-2.1
                        C275.2,295.4,277.3,293.2,280,293.2z"/>
                    <path className="st5" d="M280,291.4l15.4-0.2c2.7,0,4.9,2.2,4.9,4.8v2.1c0,2.7-2.2,4.9-4.8,4.9l-15.4,0.2c-2.7,0-4.9-2.2-4.9-4.8v-2.1
                        C275.1,293.7,277.3,291.5,280,291.4z"/>
                    <path className="st0" d="M377.8,331.2v-0.3l0,0l-0.1-3.7l0,0v-0.5l0,0c-0.2-1.7-1.3-3.3-3.4-4.5c-4.5-2.7-11.7-2.5-16.1,0.3
                        c-2,1.3-3.1,2.9-3.2,4.6l0,0l0.1,4.2l0,0v0.3l0,0c0.2,1.7,1.3,3.4,3.4,4.6c4.5,2.7,11.7,2.5,16.1-0.3
                        C376.6,334.6,377.7,332.9,377.8,331.2L377.8,331.2z"/>
                    <g>
                        <g>
                            <path className="st14" d="M369.5,334.1c-4.4,2.9-11.6,3-16.1,0.3s-4.6-7.2-0.2-10.1s11.6-3,16.1-0.3
                                C373.8,326.8,373.9,331.3,369.5,334.1z"/>
                            
                                <rect x="350" y="325.1" transform="matrix(0.9998 -1.975020e-02 1.975020e-02 0.9998 -6.3957 7.2016)" className="st14" width="22.8" height="4.6"/>
                            <path className="st5" d="M369.5,330.4c-4.4,2.9-11.6,3-16.1,0.3s-4.6-7.2-0.2-10.1s11.6-3,16.1-0.3S373.9,327.5,369.5,330.4z"/>
                            <path className="st14" d="M367.3,328.6c-3.2,2.1-8.5,2.2-11.8,0.2s-3.4-5.3-0.1-7.4c3.2-2.1,8.5-2.2,11.8-0.2
                                C370.4,323.2,370.5,326.5,367.3,328.6z"/>
                        </g>
                        <path className="st19" d="M366.7,325.6l-11.6,0.4c-0.6,0-1.1-0.5-1.2-1.1l0,0c0-0.6,0.5-1.1,1.1-1.2l11.6-0.4c0.6,0,1.1,0.5,1.2,1.1
                            l0,0C367.8,325.1,367.3,325.6,366.7,325.6z"/>
                    </g>
                    <path className="st0" d="M305.5,327l0.4-0.2l0,0l2.5-1.4l4-2.2l0,0l9.4-5.2c3.8-2.1,8.6-0.8,10.8,2.9c2.1,3.7,0.8,8.5-3,10.6l-9.1,5.1
                        l0,0l-2.3,1.3l-0.4,0.2l0,0l-0.8,0.5l-3.5,2c-3.8,2.1-8.6,0.8-10.8-2.9l0,0C300.4,333.9,301.7,329.1,305.5,327z"/>
                    
                    <Pill>
                        <path className="st5" d="M326.3,329.5l-16.6,9.4c-3.9,2.2-9,0.8-11.2-3.1l0,0c-2.2-3.9-0.8-9,3.1-11.2l16.6-9.4c3.9-2.2,9-0.8,11.2,3.1
                            l0,0C331.7,322.2,330.3,327.2,326.3,329.5z"/>
                        <g>
                            <path d="M301.3,324.7l4.6-2.6c3.9-2.2,9-0.8,11.2,3.1l0,0c2.2,3.9,0.8,9-3.1,11.2l-0.9,0.5l-3.7,2.1c-3.9,2.2-9,0.8-11.2-3.1l0,0
                                C295.9,332,297.3,327,301.3,324.7z"/>
                            <path d="M302.5,324l5.9-3.4c3.9-2.2,7.7-0.1,9.9,3.9l0,0c2.2,3.9,2.4,8.1-1.5,10.4l-2.4,1.4l-3.7,2.1c-3.9,2.2-9,0.8-11.2-3.1l0,0
                                C297.2,331.3,298.6,326.3,302.5,324z"/>
                        </g>
                    </Pill>

                    <path className="st20" d="M308.7,330.5l17.6-9.3c0.8-0.4,1.1-1.4,0.7-2.3l0,0c-0.4-0.8-1.4-1.1-2.3-0.7l-17.6,9.3
                        c-0.8,0.4-1.1,1.4-0.7,2.3l0,0C306.9,330.6,307.9,331,308.7,330.5z"/>
                    <path className="st0" d="M273.2,331.4l-0.4-0.2l0,0l-2.7-1.1l-4.2-1.8l0,0l-9.8-4.2c-4-1.7-8.7,0.1-10.4,4c-1.8,3.9,0.1,8.5,4.1,10.2
                        l9.6,4.1l0,0l2.5,1.1l0.4,0.2l0,0l0.9,0.4l3.7,1.6c4,1.7,8.7-0.1,10.4-4l0,0C279,337.7,277.1,333.1,273.2,331.4z"/>
                    
                    <Pill2>
                        <path d="M247.1,334.4l17.4,7.6c4.2,1.8,9-0.1,10.9-4.3l0,0c1.8-4.2-0.1-9-4.3-10.9l-17.4-7.6c-4.2-1.8-9,0.1-10.9,4.3l0,0
                            C241,327.7,242.9,332.5,247.1,334.4z"/>
                        <g>
                            <path className="st5" d="M271.5,327.1l-4.8-2.1c-4.2-1.8-9,0.1-10.9,4.3l0,0c-1.8,4.2,0.1,9,4.3,10.9l0.9,0.4l3.9,1.7
                                c4.2,1.8,9-0.1,10.9-4.3l0,0C277.6,333.8,275.7,328.9,271.5,327.1z"/>
                            <path className="st5" d="M270.2,326.5l-6.2-2.7c-4.2-1.8-7.6,0.7-9.4,4.9l0,0c-1.8,4.2-1.6,8.3,2.6,10.1l2.6,1.1l3.9,1.7
                                c4.2,1.8,9-0.1,10.9-4.3l0,0C276.3,333.2,274.4,328.3,270.2,326.5z"/>
                        </g>
                    </Pill2>

                    <path className="st20" d="M264.7,333.6l-18.5-7.4c-0.9-0.3-1.3-1.3-0.9-2.2l0,0c0.3-0.9,1.3-1.3,2.2-0.9l18.5,7.4
                        c0.9,0.3,1.3,1.3,0.9,2.2l0,0C266.6,333.5,265.6,333.9,264.7,333.6z"/>
                    <image width="2094" height="1983" xlinkHref={SubmittLogo}  transform="matrix(2.394382e-02 1.291206e-02 -1.291206e-02 2.394382e-02 440.6073 93.1374)">
                    </image>
                </g>
            </svg>
        </Container>
    );
}
 
export default PricingIllustration;