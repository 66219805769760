import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Home from '../components/Home'
import Process from '../components/Process'
import Pricing from '../components/Pricing'
import FAQ from '../components/FAQ'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`submitt`, `submitt app`, `medical app`, 'medical claims', 'doctors']} />
    <Home />
    <Process />
    <Pricing />
    <FAQ />
  </Layout>
)

export default IndexPage
