import React, { PureComponent } from 'react'
import styled from 'styled-components'

const DATA = [
  {
    question: 'Is Submitt a Medical Billing Program?',
    answer: <p> We are not. We are however the shortcut to your medical bureau, removing paperwork and mistakes that normally occur with claims. </p>
  },
  {
    question: 'Does Submitt send my claims to Medical Aids?',
    answer: <p>Nope. Please see previous answer <span aria-label="smile" role="img">🙂</span></p>
  },
  {
    question: 'What is Submitt then?',
    answer: <p> We assist doctors in private practice by creating claims on the fly and sending these claims to their medical bureau of choice. We also keep track of where the claim is and even let you resubmit if something goes wrong. </p>
  },
  {
    question: 'Does Submitt have billing codes included?',
    answer: <p>Yip. We make sure the Bhf / Sama codes are always up to date to make your life easier when submitting a claim.</p>
  },
  {
    question: 'Will Submitt work on my smartphone and tablet?',
    answer: <p>Submitt plays well on Android and iOS devices, and works just fine on both smartphones and tablets. You do however need a stable internet connection to ensure your claims go to the cloud and off to your medical bureau.</p>
  },
  {
    question: 'How will the medical bureau receive my claims?',
    answer: <p>We will send them a neatly formatted PDF document.</p>
  },
  {
    question: 'Can any doctor use Submitt?',
    answer: <p>We cater for all medical doctors. We also allow for custom billing codes just in case!</p>
  },
  {
    question: 'How does my subscription work?',
    answer: <p>We will bill your credit card every month on the same day as you signed up. There are no contracts and you can cancel anytime.</p>
  },
  {
    question: 'Is my data safe with Submitt?',
    answer: <p>With our secure cloud based infrastructure you will benefit from a data center and network architecture built to meet the requirements of the most security-sensitive organizations. In short, absolutely!</p>
  },
]

const Container = styled.section`
  background-color: var(--alabaster);
  padding: var(--gutter-m) var(--gutter-s);
  @media (min-width: 768px) {
    padding: var(--gutter-l) var(--gutter-l);
  }

`

const Heading = styled.div`
  text-align: center;
  h1, h3 {
    color: var(--manatee);
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  h3 {
    margin-bottom: 3rem;
    font-weight: 400;
    font-size: 1.15rem;
  }
  @media (min-width: 768px) {
    h3 {
        font-size: 1.35rem;
    }
  }
`

const Block = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 2px 2px 6px -2px rgba(0,0,0,0.125);
  margin-bottom: var(--gutter-m);
`

const Question = styled.div`
  display: flex;
  padding: var(--gutter-s);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h4 {
    font-size: 1rem;
    color: var(--manatee);
  }
  svg {
    height: 24px;
    width: 24px;
    transition: 250ms ease-out;
    path {
      fill: var(--gallery);
    }
    &.open {
      transform: scale(-1, -1);
    }
  }
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background-color: var(--gallery);
  margin: 0 var(--gutter-s);
`

const Answer = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: 250ms ease-out;
  .wrapper {
    padding: var(--gutter-s);
    p {
      margin-bottom: 1rem;
      color: var(--manatee);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

class FAQ extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  closeAllBlocks = () => {
    let arrows = document.getElementsByClassName('question-arrow')
    let blocks = document.getElementsByClassName('answer-block')
    Array.from(blocks).forEach(block => {
      block.style.maxHeight = '0px'
    })
    Array.from(arrows).forEach(arrow => {
      arrow.classList.remove('open')
    })
  }

  toggleBlock = (val) => {
    let arrow = document.getElementById(`block-${val}-arrow`)
    let answer = document.getElementById(`block-${val}-answer`)
    let answerWrapper = document.getElementById(`block-${val}-answer-wrapper`)
    let targetHeight = answerWrapper.getBoundingClientRect().height
    if (arrow.classList.contains('open')) {
      arrow.classList.remove('open')
      answer.style.maxHeight = '0px'
    } else {
      this.closeAllBlocks()
      arrow.classList.add('open')
      answer.style.maxHeight = `${targetHeight}px`
    }
  }

  renderBlocks = () => {
    return DATA.map((block, i) => {
      return (
        <Block key={i}>
          <Question onClick={() => this.toggleBlock(i)}>
            <h4>{block.question}</h4>
            <svg viewBox="0 0 24 24" id={`block-${i}-arrow`} className="question-arrow">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
            </svg>
          </Question>
          <Answer id={`block-${i}-answer`} className="answer-block">
            <Line />
            <div id={`block-${i}-answer-wrapper`} className="wrapper">{block.answer}</div>
          </Answer>
        </Block>
      )
    })
  }
  render() { 
    return (
        <Container name="faq">
            <Heading>
                <h1 className="font-bold">FAQ</h1>
                <h3>Have any questions?</h3>
            </Heading>
            {this.renderBlocks()}
        </Container>
    );
  }
}
 
export default FAQ;
