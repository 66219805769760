import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import app_store from "../images/icons/apple-logo-white.svg"
import play_store from "../images/icons/google-logo-white.svg"
import PricingIllustration from "./illustrations/pricingIllustration"

const Container = styled.section`
  display: grid;
  grid-template: 1fr auto / 1fr;
  padding: var(--gutter-m) var(--gutter-s);
  align-items: center;
  background-color: var(--malibu);
  @media (min-width: 768px) {
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 0 var(--gutter-l);
    padding: var(--gutter-l) var(--gutter-l);
  }
`

const Button = styled(Link)`
  background: rgb(80, 125, 220);
  background: linear-gradient(
    90deg,
    rgba(80, 125, 220, 1) 35%,
    rgba(97, 160, 255, 1) 100%
  );
  color: var(--white) !important;
  padding: 0.75rem 2rem !important;
  border: none;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.9rem;
  transition: all 250ms ease-out;
  margin-bottom: 2rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:after {
    content: none !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 4rem;
  }
`

const InfoBlock = styled.div`
  padding-bottom: var(--gutter-m);
  h1,
  h3,
  p {
    color: var(--white);
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    span {
      font-size: 1.25rem;
    }
  }
  h3 {
    font-size: 0.8rem;
    line-height: 150%;
  }
  p {
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 2rem;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 5rem;
    }
  }
`

const Hero = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  padding: var(--gutter-m) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
    max-width: 300px;
  }
  @media (min-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    padding: var(--gutter-m) 0;
    div {
      max-width: 550px;
    }
  }
`

const AppStoreContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  img {
    width: 100px;
    height: auto;
    &:first-child {
      margin-right: 2rem;
    }
  }
  @media (min-width: 768px) {
    img {
      width: 150px;
    }
  }
`

const Pricing = () => {
  return (
    <Container name="pricing">
      <h1 style={{ display: "none" }}>Pricing</h1>
      <InfoBlock>
        <h1 className="font-bold">
          R199
          <span className="font-semi-bold">per user / month</span>
        </h1>
        <p>
          Submitt removes the paperwork and helps you get your claims submitted
          to your medical bureau of choice faster than ever, meaning you get
          paid faster. Only R199 per user, per month gives you all the tools to
          run your practice.
        </p>
        <AppStoreContainer>
          <a
            href="https://apps.apple.com/za/app/submitt/id1457848422"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={app_store} alt="Submitt App Store download" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=me.submitt"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={play_store} alt="Submitt Play Store download" />
          </a>
        </AppStoreContainer>
        <Button to="/sign-up">Get Started</Button>
        <h3 className="font-light">NO CONTRACTS. CANCEL ANYTIME.</h3>
      </InfoBlock>
      <Hero>
        {/* <img src={hero} alt="Submitt App"/> */}
        <PricingIllustration />
      </Hero>
    </Container>
  )
}

export default Pricing
